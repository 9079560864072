import React, {useState, useEffect} from 'react';
import './SignUpComponent.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import logoIconFinalColor from '../../utils/logoIconFinalColor.svg'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useAuth } from '../../contexts/AuthContext'
import { createUser, getUserIdByUsername } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next';
import { HOME_LOGO_ICON_WIDTH, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX, USERNAME_CHARACTER_MAX, USERNAME_CHARACTER_MIN, USERNAME_REGEX
	,SHOW_HOME_SLOGAN_WIDTH 
	 } from '../../utils/Constants';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { isUserAllowedToSignUp } from '../../firebaseFunctions/spannerFunctions/profileFunctions';

import logoWhite from '../../utils/logoWhite.svg'

const theme = createTheme();

const StyledButtonAccept = styled(Button)(({ theme }) => ({
	backgroundColor: '#436efa!important'
}))

export default function SignUpComponent(props) {
	const { t } = useTranslation();

	const { signUp, setIsSigningUpForContext, setCurrentProfileForContext, isSigningUp, setIsCreatingUserForContext } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	

	const [userAllowedToSignUp, setUserAllowedToSignUp] = useState(null)
	
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)	
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
		setWindowHeight(window.innerHeight)
	}	
	useEffect(() => {  
	// Anything in here is fired on component mount.
	isUserAllowedToSignUp().then((res) => {
		setUserAllowedToSignUp(res)
	})	
	window.addEventListener('resize', setWindowDimensions);
	return () => {
		window.removeEventListener('resize', setWindowDimensions)
	}

	}, [])
	
	async function handleSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if ((data.get('password') === "")|| (data.get('email') === "") || (data.get('username') === "")){
			return setError(t("signUpComponent.allFieldsRequired"))
		}else {
			if (data.get('password').length < PASSWORD_MIN_LENGTH || data.get('password').length > PASSWORD_MAX_LENGTH ){
				return setError(t("signUpComponent.passwordCharacterMinimun"))
			} else {
				if (data.get('password') !== data.get('confirmPassword')) {
					return setError(t("signUpComponent.passwordDoesNotMatch"))
				} else {
					const passwordValid = PASSWORD_REGEX.test(data.get('password'))
					if (passwordValid) {
						const usernameValid = USERNAME_REGEX.test(data.get('username'));
						if (usernameValid) {
							if (data.get('username').length < USERNAME_CHARACTER_MIN || data.get('username').length > USERNAME_CHARACTER_MAX) {
								return setError(t("signUpComponent.usernameCharacterMinimum"))
							} else {
								try {
									setError("")
									setLoading(true)
									setIsSigningUpForContext(true)
									
									// Create user 
									await getUserIdByUsername(data.get('username')).then((userCreated) => {
										if (userCreated === null) {
											signUp(data.get('email'), data.get('password')).then(() => {
												setIsCreatingUserForContext(true)
												createUser(data.get('username')).then((userCreated2) => {
													// user has been created, navigate to home
													if (userCreated2.success) {
														// user has been created, navigate to home
														setCurrentProfileForContext(true)
														setIsSigningUpForContext(false)
														setIsCreatingUserForContext(false)
													} else {
														if (userCreated2.error) {
															setIsSigningUpForContext(false)
															setIsCreatingUserForContext(false)
															setError(t("genericErrorMessage"))
														}
													}
												})
											}).catch((e) => {
												// Could not sign up. This could happen if email is already in use.
												setIsSigningUpForContext(false)
												setError(t("signUpComponent.accountCreationFailed"))
											})
										} else {
											// user was not created because:
											setIsSigningUpForContext(false)
											setError(t("signUpComponent.usernameAlreadyExists")) 
										}
									})
								} catch (e){
									setIsSigningUpForContext(false)
									setError(t("signUpComponent.accountCreationFailed"))
								} 
								setLoading(false)
							}
						} else {
							setError(t("signUpComponent.usernameCharacterType"))
						}
					}else {
						setError(t("signUpComponent.passwordCharacterType"))

					}
				}
			}
		}
	}
		
	const [acceptsTermsAndConditions, setAcceptsTermsAndConditions] = useState(false)
	const [readTermsAndConditions, setReadTermsAndConditions] = useState(false)
	const [openTermsAndConditionsDialog, setOpenTermsAndConditionsDialog] = useState(false);
  
	const handleClickOpenTermsAndConditionsDialog = () => {
		setOpenTermsAndConditionsDialog(true);
	};
	const handleCloseTermsAndConditionsDialog = () => {
	  setOpenTermsAndConditionsDialog(false);
	};
	const handleAcceptTermsAndConditions = () => {
		setAcceptsTermsAndConditions(true)
		handleCloseTermsAndConditionsDialog()
	}
	
	const [acceptsPrivacyPolicy, setAcceptsPrivacyPolicy] = useState(false)
	const [readPrivacyPolicy, setReadPrivacyPolicy] = useState(false)
	const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] = useState(false);
	
	const handleClickOpenPrivacyPolicyDialog = () => {
		setOpenPrivacyPolicyDialog(true);
	};
	const handleClosePrivacyPolicyDialog = () => {
		setOpenPrivacyPolicyDialog(false);
	};
	const handleAcceptPrivacyPolicy = () => {
		setAcceptsPrivacyPolicy(true)
		handleClosePrivacyPolicyDialog()
	}

	const handleTermsAndConditionsScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (scrollTop + clientHeight >= scrollHeight -1 || readTermsAndConditions) {
		  	setReadTermsAndConditions(true);
		} else {
			setReadTermsAndConditions(false);
		}
	}
	const handlePrivacyPolicyScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (scrollTop + clientHeight >= scrollHeight -1 || readPrivacyPolicy) {
		  	setReadPrivacyPolicy(true);
		} else {
			setReadPrivacyPolicy(false);
		}
	}
	
	const [informationIsTruthful, setInformationIsTruthful] = useState(false)
	const handleClickTruthfulInformationChange = () =>{
		setInformationIsTruthful(prev=>!prev)
	}
	return (
		<>
			{
			userAllowedToSignUp !== false && !userAllowedToSignUp ?
				<ThemeProvider theme={theme}>
					<Container component="main" maxWidth="xs" >
					<Box sx={{ display: 'flex',
						alignContent: 'center', 
						height: "calc(100vh - 360px)",
						alignItems:"center",
						justifyContent:"center"}}>
						<CircularProgress sx={{color:'white'}}/>
					</Box> 
					</Container>
				</ThemeProvider>
				:
				userAllowedToSignUp === false ?
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={2}
						sx={{marginLeft: "25%", marginTop: "10%", marginRight: "25%", marginBottom: "10%"}}
						>
						<Typography variant="h2" sx={{textAlign: "center"}}>	
							{t("signUpComponent.userNotAllowedToSignup")}
						</Typography>
					</Stack>
					:
					<>
						<Dialog
							open={openTermsAndConditionsDialog}
							onClose={handleCloseTermsAndConditionsDialog}
							scroll='paper'
							aria-labelledby="scroll-dialog-title"
							aria-describedby="scroll-dialog-description"
						>
							<DialogTitle id="scroll-dialog-title">
								<Typography variant='h2'>
									{t('legals.termsAndConditions')}
								</Typography>
							</DialogTitle>
							<DialogContent dividers onScroll={handleTermsAndConditionsScroll}>
								<DialogContentText
									id="scroll-dialog-description">
									{t("legals.termsAndConditionsContent", { returnObjects: true })
										.map((item) => {return (
											<Grid
												container
												direction="column"
												sx={{
													justifyContent: "center",
													alignItems: "center",
													marginTop: '16px'
												}}
												>
												<Grid item>
													<Typography variant='h3' sx={{ textAlign: 'center' }}>
														{item.title}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='body1'>
														{item.content}
													</Typography>
												</Grid>
											</Grid>
										)
										})}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<StyledButtonAccept variant='contained' disabled={!readTermsAndConditions} onClick={() => handleAcceptTermsAndConditions()}>{t('legals.accept')}</StyledButtonAccept>
							</DialogActions>
						</Dialog>
						<Dialog
							open={openPrivacyPolicyDialog}
							onClose={handleClosePrivacyPolicyDialog}
							scroll='paper'
							aria-labelledby="scroll-dialog-title"
							aria-describedby="scroll-dialog-description"
						>
							<DialogTitle id="scroll-dialog-title">
								<Typography variant='h2'>
									{t('legals.privacyPolicy')}
								</Typography>
							</DialogTitle>
							<DialogContent dividers onScroll={handlePrivacyPolicyScroll}>
								<DialogContentText
									id="scroll-dialog-description">
									{t("legals.privacyPolicyContent", { returnObjects: true })
										.map((item) => {return (
											<Grid
												container
												direction="column"
												sx={{
													justifyContent: "center",
													alignItems: "center",
													marginTop: '16px'
												}}
												>
												<Grid item>
													<Typography variant='h3' sx={{ textAlign: 'center' }}>
														{item.title}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='body1'>
														{item.content}
													</Typography>
												</Grid>
											</Grid>
										)
										})}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<StyledButtonAccept variant='contained' disabled={!readPrivacyPolicy} onClick={() => handleAcceptPrivacyPolicy()}>{t('legals.accept')}</StyledButtonAccept>
							</DialogActions>
						</Dialog>	
						<ThemeProvider theme={theme}>
							<Container component="main" maxWidth="xs" >
								<Box
									sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									}}
								>
								
									{error && <Alert sx={{height: "auto!important", width: "100%!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
									{props.homePage ? 
										<>
										{
											windowWidth < SHOW_HOME_SLOGAN_WIDTH ?						
												<img src={logoWhite} style={{width:'80%', minWidth:'220px', marginTop:'10%'}} alt="logo" />
												:				
												<img src={logoIconFinalColor} width={HOME_LOGO_ICON_WIDTH} alt="logo" />
										}
										<Typography component="h1" variant="h6" sx={{color: "#f8f8f8!important"}}>
											{t("signUp")}
										</Typography>
										</>
										: null	
									}
									<Box component="form" noValidate onSubmit={handleSubmit}>
										<Grid container spacing={0}>
											<Grid item xs={12} sm={6}>
												<TextField
													margin="dense"
													autoComplete="user-name"
													className='HomePageTextField'
													name="username"
													required
													spellCheck={false}
													fullWidth
													id="username"
													label={t("username")}
													autoFocus
													size="small"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													margin="dense"
													className='HomePageTextField'
													required
													spellCheck={false}
													fullWidth
													id="email"
													label={t("emailAdress")}
													name="email"
													autoComplete="email"
													size="small"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													margin="dense"
													className='HomePageTextField'
													spellCheck={false}
													required
													fullWidth
													name="password"
													label={t("password")}
													type="password"
													id="password"
													autoComplete="new-password"
													size="small"
													
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													margin="dense"
													className='HomePageTextField'
													required
													spellCheck={false}
													fullWidth
													name="confirmPassword"
													label={t("confirmPassword")}
													type="password"
													id="confirmPassword"
													autoComplete="confirm-new-password"
													size="small"
												/>
											</Grid>
											<Grid item xs={12}>
												<Button sx={{width: '100%', justifyContent: 'flex-start'}} onClick={() => handleClickOpenTermsAndConditionsDialog()}>
													<FormControlLabel style={{marginRight: 3}}
														control={<Checkbox sx={{
															color: '#f8f8f8',
																'&:hover': { backgroundColor: 'transparent' }
															}} value="open" color="primary" name="acceptsTermsAndConditionsDrawerCheckboxChecked" 
															checked={acceptsTermsAndConditions}/>}
															label={
																<Typography variant='caption' sx={{textTransform: 'none', textAlign: 'left'
																	,color: '#f8f8f8',
																}}>
																	{t('legals.acceptTermsAndConditions')}
																</Typography>}
															/>
												</Button>
											</Grid>
											<Grid item xs={12}>
												<Button sx={{width: '100%', justifyContent: 'flex-start'}} onClick={() => handleClickOpenPrivacyPolicyDialog()}>
													<FormControlLabel style={{marginRight: 3}}
														control={<Checkbox sx={{
															color: '#f8f8f8',
																'&:hover': { backgroundColor: 'transparent' }
															}} value="open" color="primary" name="acceptsPrivacyPolicyDrawerCheckboxChecked" 
															checked={acceptsPrivacyPolicy}/>}
															label={
																<Typography variant='caption' sx={{textTransform: 'none', textAlign: 'left'
																	,color: '#f8f8f8',
																}}>
																	{t('legals.acceptPrivacyPolicy')}
																</Typography>}
														/>
												</Button>
											</Grid>
											<Grid item xs={12}>
												<Button sx={{width: '100%', justifyContent: 'flex-start'}} >
													<FormControlLabel style={{marginRight: 3}}
													onClick={() => handleClickTruthfulInformationChange()}
														control={<Checkbox sx={{
															color: '#f8f8f8',
																'&:hover': { backgroundColor: 'transparent' }
															}} value="open" color="primary" name="informationIsTruthfulCheckboxChecked" 
															checked={informationIsTruthful}/>}
															label={
																<Typography variant='caption' sx={{textTransform: 'none', textAlign: 'left'
																	,color: '#f8f8f8',
																}}>
																	{t("legals.truthfulInformation")}
																</Typography>
															}
														/>
												</Button>
											</Grid>
										</Grid>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											className="FlowButton"
											sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
											disabled={loading || isSigningUp || !acceptsTermsAndConditions || !acceptsPrivacyPolicy || !informationIsTruthful}
										>
											{t("signUp")}
										</Button>
									</Box>
								</Box>
							</Container>
						</ThemeProvider>
					</>	
			}
		</>	
	)
}
