import React, {useState, useEffect} from 'react';
import './RightSidebarComponent.css'
import { SideAdComponent } from '..'
import Grid from '@mui/material/Grid';
import { MAX_LAPTOP_WIDTH, MAX_MOBILE_WIDTH, MIN_HEIGHT_FOR_RIGHT_SIDEBAR_AD_DISPLAY, MIN_RIGHT_SIDEBAR_WIDTH, NAVBAR_HEIGHT, RIGHT_SIDEBAR_WIDTH } from '../../utils/Constants';

export default function RightSidebarComponent(props) {
	
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
		setWindowHeight(window.innerHeight)
	}	
	useEffect(() => {  
		// Anything in here is fired on component mount.	

		window.addEventListener('resize', setWindowDimensions);
		return () => {
		  	window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])

	return (
		<>	
			{windowWidth < MAX_MOBILE_WIDTH ? 	
				<></>
			:
			<Grid item sx={{
				width: windowWidth < MAX_LAPTOP_WIDTH ? windowWidth * 0.25 +"px" : windowWidth * 0.3 +"px"
				}}>
					<Grid container item sx={{ position: 'fixed', width: "inherit", height: 'calc(100vh - ' + NAVBAR_HEIGHT + ')' }}
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start">
						{windowHeight < MIN_HEIGHT_FOR_RIGHT_SIDEBAR_AD_DISPLAY ?
							<>
							</>
							:
							<Grid 
								item 
								xs
								sx={{marginTop: '12px', width: windowWidth < MAX_LAPTOP_WIDTH ? MIN_RIGHT_SIDEBAR_WIDTH : RIGHT_SIDEBAR_WIDTH}}>
								<SideAdComponent />
							</Grid>
						}
					</Grid>
				</Grid>
			}
		</>
	)
}

