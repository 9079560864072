import { useContext,
	useEffect } from 'react';
import './RecoverPage.css'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { HomeSloganComponent, LegalNoticeComponent, RecoverComponent} from '../../components'

import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import Typography from '@mui/material/Typography';

export default function RecoverPage() {
	const { t } = useTranslation();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
	
    useEffect(() => {  
		// Anything in here is fired on component mount.	
  
		setDocumentTitle(t('documentTitles.recoverPage'))
		setDocumentDescription(t('documentDescriptions.recoverPage'))
	
		}, [])
	return (
		<Grid container component="main" sx={{ height: '100vh' }}>		   
			
			<HomeSloganComponent/>
				<Grid  sx={{paddingTop: "25px"
					,zIndex: '4!important', 
					backgroundColor: '#fff'
				}} item xs={12} sm={12} md={5}  component={Paper} 
				elevation={0} 
				square>	
				<div style={{
					height: '90vh',
					width: '90%',
					maxWidth: '400px',
					margin: '0 auto',
					borderRadius: '28px',
					backgroundImage: 'linear-gradient(45deg, #143bb8, #436efa)'
					}}>
					<RecoverComponent />
					<Grid container  justifyContent="center" spacing={2}>
						<Grid item>
							<Link to="/flow/signin" style={{textDecoration: "none", color: "inherit"}}>
								<Typography variant='subtitle2' sx={{"&:hover": {textDecoration: "underline!important"},
										color: "white!important"}}
										>
									{t("recoverPage.haveAccountSignIn")}
								</Typography>
							</Link>
						</Grid>
						<Grid item>
							<Link to="/flow/signup" style={{textDecoration: "none", color: "inherit"}}>
								<Typography variant='subtitle2' sx={{"&:hover": {textDecoration: "underline!important"},
									color: "white!important"}}
									>
									{t("recoverPage.doNotHaveAccountSignUp")}
								</Typography>
							</Link>
						</Grid>
					</Grid>						
					<LegalNoticeComponent sx={{ mt: 3 }} />
				</div>
			</Grid>
		</Grid>
	)
}

