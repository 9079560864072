import { URL_REGEX, USERNAME_CHARACTER_MAX, USERNAME_CHARACTER_MIN, MAX_USERNAMES_MENTIONED, USERNAME_REGEX, MAX_EXTERNAL_LINKS_PER_POST, MAX_LOCAL_LINKS_PER_POST, 
  NAVBAR_HEIGHT_ABSOLUTE, BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE, MAX_POST_LENGTH, URL_OR_USERNAME_REGEX, USERNAME_GLOBAL_REGEX, URL_REGEX_LOCAL } from '../../utils/Constants';
import './PostTextFieldComponent.css';

import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, TextNode, $isParagraphNode, $isLineBreakNode, $getSelection, $createLineBreakNode, $createParagraphNode } from 'lexical';
import { KEY_TAB_COMMAND,COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_HIGH } from 'lexical';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import NavigateToExternalLinkModalComponent from '../NavigateToExternalLinkModalComponent';

const TextFieldDiv = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& a': {
    pointerEvents: 'auto',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
  },
  '& .editor-input': {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight
  },
  '& .editor-placeholder': {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight
  }
}));
function onError(error) {
  // console.error(error);
}

function MyOnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();  
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
    });
    
  }, [editor, onChange]);
  return null;
}


function extractTextContent(node) {
  if (node instanceof TextNode) {
    return node.getTextContent();
  }

  if ($isLineBreakNode(node)) {
    return '\n';
  }

  if ($isParagraphNode(node)) {
    let textContent = '';
    node.getChildren().forEach(child => {
      textContent += extractTextContent(child);
    });
    return textContent + '\n\n'; // Append double newline after paragraphs to differentiate
  }

  if (node.getChildren) {
    let textContent = '';
    node.getChildren().forEach(child => {
      textContent += extractTextContent(child);
    });
    return textContent;
  }

  return '';
}

const CustomTabKeyPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_TAB_COMMAND,
      (event) => {
        event.preventDefault(); // Prevent the default tab behavior
        editor.update(() => {
          const selection = $getSelection();
          if (selection !== null) {
            selection.insertText('    '); // Insert a tab character (4 spaces)
          }
        });
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};

const CustomEnterKeyPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      'keyEnter',
      (event) => {
        event.preventDefault();
        editor.update(() => {
          const selection = $getSelection();
          if (selection !== null) {
            if (event.shiftKey) {
              const lineBreakNode = $createLineBreakNode();
              selection.insertNodes([lineBreakNode]);
            } else {
              const paragraphNode = $createParagraphNode();
              selection.insertNodes([paragraphNode]);
            }
          }
        });
        return true;
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [editor]);

  return null;
};

const CustomLinkClickHandler = (props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const handleLinkClick = (event) => {
      const target = event.target.closest('a'); // Find the closest <a> tag
      if (target) {
        event.preventDefault();
        const url = new URL(target.href);
        const currentHost = window.location.host;
        if (url.host === currentHost) {
          window.open(url.href, '_blank');
        } else {
          props.handleClickOpenNavigateToExternalLinkModal(url)
        }
      }
    };

    const editorElement = editor.getRootElement();
    if (editorElement) {
      editorElement.addEventListener('click', handleLinkClick);
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener('click', handleLinkClick);
      }
    };
  }, [editor]);

  return null;
};
const exampleTheme = {
  ltr: 'ltr',
  rtl: 'rtl',
  paragraph: 'editor-paragraph',
};
let mentionsCounterImported = 0
let externalLinksCounterImported = 0
let localLinksCounterImported = 0

const MATCHERS = [
  (text) => {
    const match = URL_OR_USERNAME_REGEX.exec(text);
    if (match === null) {
      return null;
    }

    if (URL_REGEX_LOCAL.test(match[0])) {
      const fullMatch = match[0];
      const url = fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`
      
      let localDomain = window.location.hostname;
      let linkDomain = new URL(url).hostname;
      if (localDomain !== linkDomain) {
        // link is external
        if (externalLinksCounterImported < MAX_EXTERNAL_LINKS_PER_POST) {
          return {
            index: match.index,
            length: fullMatch.length,
            text: fullMatch,
            url: url,
          };
        } 
      }
      if (localDomain === linkDomain) {
        // link is local          
        if (localLinksCounterImported < MAX_LOCAL_LINKS_PER_POST){
          return {
            index: match.index,
            length: fullMatch.length,
            text: fullMatch,
            url: url,
          };
        }
      }
    } else {
      const usernameTemp = match[0].startsWith('@') ? match[0].slice(1) : match[0]
      if (mentionsCounterImported < MAX_USERNAMES_MENTIONED && USERNAME_REGEX.test(usernameTemp) && usernameTemp.length <= USERNAME_CHARACTER_MAX && usernameTemp.length >= USERNAME_CHARACTER_MIN) {
        return {
          index: match.index,
          length: match[0].length,
          text: match[0],
          url: `https://${window.location.host}/${usernameTemp}`,
        };
        
      }
    }
    return null;
  }
];

const PostTextFieldComponent = (props) => {
  const initialConfig = {
    namespace: 'MyEditor',
    theme: exampleTheme, // Define your theme here
    onError,
    nodes: [AutoLinkNode, LinkNode], // Register the AutoLinkNode and LinkNode
  };
  const { t } = useTranslation();
	const [usernamesList, setUsernamesList] = useState([]);
  const [mentionsCounter, setMentionsCounter] = useState(0);
  const [externalLinksCounter, setExternalLinksCounter] = useState(0);
  const [localLinksCounter, setLocalLinksCounter] = useState(0);
  const [plainText, setPlainText] = useState('');
  const [mentionsLoading, setMentionsLoading] = useState([]) 

  function onChange(editorState) {
    let plainTextContent = '';
    editorState.read(() => {
      const root = $getRoot();
      plainTextContent = root.getChildren().map(node => extractTextContent(node)).join('');
      const characterCount = plainTextContent.length
      if (characterCount > MAX_POST_LENGTH) {
        setTextfieldErrorMessage(t('postTextFieldComponent.pastedTextIsTooLong'))
        openTextfieldErrorAlert()
      }
      setPlainText(plainTextContent || '');
    });
  }
  useEffect(() => {
    updateTextParameters(plainText)
  }, [plainText])
  const updateTextParameters = (plainTextContent) => {
      // Add links parts:
      const linksParts = plainTextContent.split(URL_REGEX);
      let localLinksCounter = 0
      let externalLinksCounter = 0
      linksParts.forEach((part, index) => {
        if (URL_REGEX.test(part)) {
          // Check if its external link or local
          const url = part.startsWith('http') ? part : `https://${part}`;
          let localDomain = window.location.hostname;
          let linkDomain = new URL(url).hostname;
          // always return only the domain not the www. would only work if textfield only allows www. as suffix and not any other suffix like blog. .
          if (linkDomain.startsWith('www.') && linkDomain.split('.').length > 2) {
            linkDomain = linkDomain.slice(4);
          } 
          if (localDomain.startsWith('www.') && localDomain.split('.').length > 2) {
            localDomain = localDomain.slice(4);
          }

          if (localDomain !== linkDomain) {
            // link is external
            externalLinksCounter += 1
          } else {
            // link is local
            localLinksCounter += 1
          }
        } 
      });

      if (localLinksCounter > MAX_LOCAL_LINKS_PER_POST) {
        setTextfieldErrorMessage(t('postTextFieldComponent.maxLocalLinksAlert'))
        openTextfieldErrorAlert()
      }
      if (externalLinksCounter > MAX_LOCAL_LINKS_PER_POST) {
        setTextfieldErrorMessage(t('postTextFieldComponent.maxExternalLinksAlert'))
        openTextfieldErrorAlert()
      }

      setLocalLinksCounter(localLinksCounter)
      setExternalLinksCounter(externalLinksCounter)

      // Handle usernames mentions
      let usernameMatch;
      const usernameMatches = [];

      // Loop to find all matches
      while ((usernameMatch = USERNAME_GLOBAL_REGEX.exec(plainTextContent)) !== null) {
        if (usernameMatch && usernameMatch[1] && !usernameMatches.includes(usernameMatch[1]) && usernameMatch[1].length >= USERNAME_CHARACTER_MIN && usernameMatch[1].length <= USERNAME_CHARACTER_MAX) {
          usernameMatches.push(usernameMatch[1].startsWith('@') ? usernameMatch[1].slice(1) : usernameMatch[1]);
        }
      }
      const finalUsernamesList = usernameMatches.map((username) => {
        if ((USERNAME_REGEX.test(username) && username.length <= USERNAME_CHARACTER_MAX && username.length >= USERNAME_CHARACTER_MIN)) {
          if (!usernamesList.some(user => user.username === username)) {
            return { username: username, id: null, userIdSearched: false }
          } else {
            const u = usernamesList.find(user => user.username === username)
            return u
          }
        }
        return null
      }).filter(item => item !== null);
      setUsernamesList(finalUsernamesList)   
      if (usernamesList.length > MAX_USERNAMES_MENTIONED) {
        setTextfieldErrorMessage(t('postTextFieldComponent.maxMentionsAlert'))
        openTextfieldErrorAlert()
      }  
      setMentionsCounter(usernamesList.length)
      
      props.onTextChange(plainTextContent.toString(), mentionsCounter, externalLinksCounter, localLinksCounter)
  }

  useEffect(() => {
    mentionsCounterImported = mentionsCounter
  }, [mentionsCounter])
  useEffect(() => {
    externalLinksCounterImported = externalLinksCounter
  }, [externalLinksCounter])
  useEffect(() => {
    localLinksCounterImported = localLinksCounter
  }, [localLinksCounter])

  // Handle click on link
  const [openNavigateToExternalLinkModal, setOpenNavigateToExternalLinkModal] = useState(false);
  const [urlNavigateToExternalLinkModal, setUrlNavigateToExternalLinkModal] = useState(false);
  const handleClickOpenNavigateToExternalLinkModal = (url) => {
    setOpenNavigateToExternalLinkModal(true);
    setUrlNavigateToExternalLinkModal(url)
  };
  const handleCloseNavigateToExternalLinkModal = () => {
    setOpenNavigateToExternalLinkModal(false);
  };

  
  const [textfieldErrorMessage, setTextfieldErrorMessage] = useState();
  const [textfieldErrorAlertOpen, setTextfieldErrorAlertOpen] = useState(false);
	const openTextfieldErrorAlert = () => {
		setTextfieldErrorAlertOpen(true);
	};
  
	const deleteUserCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setTextfieldErrorAlertOpen(false);
	};
  return (
    <TextFieldDiv>
      <Snackbar open={textfieldErrorAlertOpen} autoHideDuration={6000} onClose={deleteUserCloseSuccessAlert}>
        {
          textfieldErrorMessage ? 
            <Alert onClose={deleteUserCloseSuccessAlert} severity="error" sx={{ width: '100%' }}>
              {textfieldErrorMessage}
            </Alert>
            :
            null
        }
      </Snackbar>
      <NavigateToExternalLinkModalComponent open={openNavigateToExternalLinkModal} url={urlNavigateToExternalLinkModal} handleCloseDialog={() => handleCloseNavigateToExternalLinkModal} />
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextPlugin 
          contentEditable={<ContentEditable style={{height: props.type === 'reply' ? `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE}px)`
          : (props.open ? `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE*2 + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE * 3.7}px)` : `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE*2 + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE * 4}px)`)
          , transition: 'height 0.3s'
        }} 
          className="editor-input" spellCheck={false} autoCorrect="off" autoCapitalize="off" autoComplete="off"/>}
          placeholder={<div className="editor-placeholder">{t('postTextFieldComponent.placeholder')}</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <MyOnChangePlugin onChange={onChange} />
        <CustomEnterKeyPlugin />
        <CustomTabKeyPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
        <CustomLinkClickHandler handleClickOpenNavigateToExternalLinkModal={(url) => handleClickOpenNavigateToExternalLinkModal(url)}/>
      </LexicalComposer>
    </TextFieldDiv>
  );
};

export default PostTextFieldComponent;
