import {useContext, useEffect, useState} from 'react';
import './SignUpPage.css'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { HomeSloganComponent, LegalNoticeComponent, SignUpComponent, AppUsersQuantityLimitComponent} from '../../components'

import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import Typography from '@mui/material/Typography';
import { getAppUsersQuantity } from '../../firebaseFunctions/spannerFunctions/appUsersQuantityFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function SignUpPage() {
	const { t } = useTranslation();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
	const [userLimitReached, setUserLimitReached] = useState(null)
		
	useEffect(() => {  
		// Anything in here is fired on component mount.	
		
		setDocumentTitle(t('documentTitles.signUp'))
		setDocumentDescription(t('documentDescriptions.signUp'))
		getAppUsersQuantity().then((res) => {
			if (res !== null) {
				setUserLimitReached(res.userLimitReached)		
			}
		})
	
		}, [])
	return (
		<>
		{
			<Grid container component="main" sx={{ height: '100vh', overflow:'auto!important' }}> 
				
				<HomeSloganComponent/>
				{
					userLimitReached !== null ? 
					<Grid  
						sx={{paddingTop: "25px"
							,zIndex: '4!important', 
							backgroundColor: '#fff'
						}} item xs={12} sm={12} md={5}  component={Paper} 
						elevation={0} 
						square>
						<div style={{
							height: '90vh',
							width: '90%',
							maxWidth: '400px',
							margin: '0 auto',
							overflow:'auto',
							borderRadius: '28px',
							backgroundImage: 'linear-gradient(45deg, #143bb8, #436efa)'
							}}>		
							{ 
								userLimitReached === false ?				
									<SignUpComponent homePage={true} />
									:
									<AppUsersQuantityLimitComponent/>
							}
							<Grid container justifyContent="center" spacing={2}>
								<Grid item>
									<Link to="/flow/signin" style={{textDecoration: "none", color: "inherit"}}>
										<Typography variant='subtitle2' sx={{"&:hover": {textDecoration: "underline!important"},
											color: "white!important"}}
											>
											{t("signUpPage.haveAccountSignIn")}
										</Typography>
									</Link>
								</Grid>
							</Grid>

							<LegalNoticeComponent sx={{ mt: 3 }} />
						</div>
					</Grid>
					:	
					<Grid  
						sx={{paddingTop: "25px"
							,zIndex: '4!important', 
							backgroundColor: '#ffffff'
						}} item xs={12} sm={12} md={5}  component={Paper} 
						elevation={0} 
						square>
						<div style={{
							height: '90vh',
							width: '90%',
							maxWidth: '400px',
							margin: '0 auto',
							borderRadius: '28px',
							backgroundImage: 'linear-gradient(45deg, #143bb8, #436efa)'
							}}>
							<Box sx={{ display: 'flex',
								alignContent: 'center', 
								height: "calc(100vh - 360px)",
								alignItems:"center",
								justifyContent:"center"}}>
								<CircularProgress sx={{color:'white'}}/>
							</Box> 
						</div>
					</Grid>
				}
			</Grid>
		}
		</>
	)
	
}


