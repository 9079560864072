import React, {useState, useEffect} from 'react';
import './StickyCollapsibleInfoBarComponent.css'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { MAX_LAPTOP_WIDTH, MAX_MOBILE_WIDTH, NAVBAR_HEIGHT, NAVBAR_HEIGHT_ABSOLUTE, REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT, STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT, STICKY_COLLAPSIBLE_EXPANDED_HEIGHT, STICKY_COLLAPSIBLE_FIXED_HEIGHT } from '../../utils/Constants';

export default function StickyCollapsibleInfoBarComponent(props) {


    const [prevScrollPosition, setPrevScrollPosition] = useState(0);
    const [shadow, setShadow] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const setWindowDimensions = () => {
      setWindowWidth(window.innerWidth)
    }	
    useEffect(() => {  
      // Anything in here is fired on component mount.	
  
      window.addEventListener('scroll', handleScroll);

      window.addEventListener('resize', setWindowDimensions);
      return () => {
          window.removeEventListener('resize', setWindowDimensions)
          window.removeEventListener('scroll', handleScroll);
      }
  
      }, [])
  
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setShadow((prevScrollPosition > currentScrollPosition && prevScrollPosition - currentScrollPosition > STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT) || currentScrollPosition < STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT)

      setPrevScrollPosition(currentScrollPosition);
    };

    const fixedBarStyle = {
      position: 'fixed',
      marginTop: NAVBAR_HEIGHT,
      top: 0,
      left: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      right: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      zIndex: 1
    }
    const replyButtonFixedBarStyle = {
      position: 'fixed',
      marginTop: NAVBAR_HEIGHT_ABSOLUTE + 
        (prevScrollPosition <= STICKY_COLLAPSIBLE_EXPANDED_HEIGHT - STICKY_COLLAPSIBLE_FIXED_HEIGHT ? (prevScrollPosition > 0 ? STICKY_COLLAPSIBLE_EXPANDED_HEIGHT - prevScrollPosition + 12 : STICKY_COLLAPSIBLE_EXPANDED_HEIGHT+ 12) : STICKY_COLLAPSIBLE_FIXED_HEIGHT) + "px!important",
      top: 0,
      left: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      right: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      zIndex: 1
    }

    const baseBarStyle = {
      width: windowWidth < MAX_MOBILE_WIDTH ? (windowWidth + "px") : windowWidth < MAX_LAPTOP_WIDTH ? (windowWidth * 0.5 - 38 +"px") : (windowWidth * 0.4 - 38 +"px"),
      boxShadow: "none!important",
      WebkitBoxShadow: "none!important",
      MozBoxShadow: "none!important"
    }

    const collapsedBarStyle = {
      width: windowWidth < MAX_MOBILE_WIDTH ? (windowWidth + "px") : windowWidth < MAX_LAPTOP_WIDTH ? (windowWidth * 0.5 - 38 +"px") : (windowWidth * 0.4 - 38 +"px"),
      position: 'fixed',
      marginTop: NAVBAR_HEIGHT,
      top: 0,
      left: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      right: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      zIndex: 1,
      boxShadow: "none!important",
      WebkitBoxShadow: "none!important",
      MozBoxShadow: "none!important"
    }
    const collapsedBarReplyButtonMobileStyle = {
      width: windowWidth < MAX_MOBILE_WIDTH ? (windowWidth + "px") : windowWidth < MAX_LAPTOP_WIDTH ? (windowWidth * 0.5 - 38 +"px") : (windowWidth * 0.4 - 38 +"px"),
      position: 'fixed',
      marginTop:  STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT + "px",
      top: STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT + "px",
      left: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      right: windowWidth < MAX_MOBILE_WIDTH ? "0px" : "auto",
      zIndex: 1,
      boxShadow: "none!important",
      WebkitBoxShadow: "none!important",
      MozBoxShadow: "none!important",
      borderEndStartRadius: "40px!important", borderEndEndRadius: "40px!important", 
    }

    const expandedComponentStyle = {
      marginTop: "-12px",
      boxShadow: "none!important",
      WebkitBoxShadow: "none!important",
      MozBoxShadow: "none!important"
    }
    const collapsedBarReplyButtonStyle = {
      marginTop: "-12px",
      boxShadow: "none!important",
      WebkitBoxShadow: "none!important",
      MozBoxShadow: "none!important",
      borderEndStartRadius: "30px!important", borderEndEndRadius: "30px!important",
    }
    return (
      <>
        {
          windowWidth < MAX_MOBILE_WIDTH || (props.expandedComponent === null && props.fixedComponent === null) ? 
            <>
              <Paper sx={{ ...baseBarStyle, ...expandedComponentStyle, borderTop: 'none!important', marginTop: "0px!important",  height: STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT + (props.replyButton !== null && props.userStickyCollapsibleInfoBar !== true ? REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT - 8 : -6) + "px",
                borderEndStartRadius: "30px!important", borderEndEndRadius: "30px!important",
                }}>
                <Grid container
                  direction="column"
                  justifyContent="center">
                  <Grid item sx={{ height: STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT + "px!important"}} />
                </Grid>
              </Paper>
              <Paper className='StickyCollapsibleInfoBar' sx={{ ...collapsedBarStyle, marginTop: STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT+"px", height: "inherit!important" }}>
                <div style={{margin: "6px"}}>
                  {props.collapsedComponent}
                </div>
              </Paper>
              {
                props.replyButton !== null && props.userStickyCollapsibleInfoBar !== true ? 
                 
                <Paper className='StickyCollapsibleInfoBar' sx={{ ...collapsedBarReplyButtonMobileStyle, height: REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT + "px!important"  }}>
                  <div style={{margin: "0px"}}>
                    {props.replyButton}
                  </div>
                </Paper> : null
              }
            </>
          :
            <>
              <Paper className='StickyCollapsibleInfoBar' sx={{ ...baseBarStyle, ...fixedBarStyle, borderTop: 'none!important', height: STICKY_COLLAPSIBLE_FIXED_HEIGHT + "px!important"
                , borderRadius: '0px!important'
                }}>
                <div style={{ margin: "12px", marginLeft: "22px", marginRight: "22px", marginTop: "6px"}}>
                  {props.fixedComponent}
                </div>
              </Paper> 
              <>
                <Paper className='StickyCollapsibleInfoBar' sx={{ ...baseBarStyle, ...expandedComponentStyle,
                  borderEndStartRadius: "30px!important", borderEndEndRadius: "30px!important", height: "auto", 
                  maxHeight: (props.replyButton !== null && props.userStickyCollapsibleInfoBar !== true ? REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT : 0) + STICKY_COLLAPSIBLE_EXPANDED_HEIGHT + STICKY_COLLAPSIBLE_FIXED_HEIGHT + "px"
                  }}>
                  <Grid container
                    direction="column" sx={{height: props.userStickyCollapsibleInfoBar === true && !props.userBioIsNull ? "270px" : null}}
                    justifyContent="center">
                    <Grid item sx={{ height: STICKY_COLLAPSIBLE_FIXED_HEIGHT + "px!important"}} />
                    <Grid item xs="auto" sx={{ marginBottom: "6px"}}>
                        {props.expandedComponent}
                    </Grid>
                    
                    {
                      props.replyButton !== null && props.userStickyCollapsibleInfoBar !== true ? 
                      
                      <Grid item className='StickyCollapsibleInfoBar' sx={{ ...baseBarStyle, ...collapsedBarReplyButtonStyle, ...replyButtonFixedBarStyle, height: (props.replyButton !== null ? REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT : 0) + "px!important" , border: 'none!important'}}>
                        <div style={{marginTop: "10px" }}>
                          {props.replyButton}
                        </div>
                      </Grid> : null
                    }
                  </Grid>
                </Paper>
              </>
            </>
        }
      </>
    );
}

