import React, {useState, useEffect} from 'react';
import './FeedPostsCustomizationSwitchComponent.css'
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

const CustomSwitch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
		backgroundColor:'rgba(255, 255, 255, 0.95)!important'
	},
  }));
export default function FeedPostsCustomizationSwitchComponent(props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const [checked, setChecked] = useState();
	const [loading, setLoading] = useState(true)
	const location = useLocation();
	
	// Check localStorage on mount to determine the initial state
	useEffect(() => {
		const savedState = localStorage.getItem("showRecommendedPostsOnFeed"); 
		if (savedState === "true") {
			setChecked(true); 
			setLoading(false)
		} else {
			if (savedState === null) {
				localStorage.setItem("showRecommendedPostsOnFeed", true);
				setChecked(true);
				setLoading(false)
			} else {
				setChecked(false);
				setLoading(false)
			} 
		}
	}, []);
	const handleToggle = (event) => {
		const isOn = event.target.checked;
		setChecked(isOn);
		localStorage.setItem("showRecommendedPostsOnFeed", isOn);

		if (location.pathname === '/') {
			// if user is on feed page refresh
  			window.location.reload();
		}
	  };
	return (
		<>
			{!loading ? 
				props.mobile === true ?
					<ListItem>
						<FormControlLabel
							sx={{
								borderRadius: '12px!important',
								transition: '0s!important',
								marginTop:'8px!important',
								height:'40px!important',
								width: "inherit",
								backgroundColor: 'transparent!important',
								justifyContent: 'flex-start', 
								marginLeft: '7px',
								'&:hover': {
									backgroundColor: 'transparent!important',
								}, 
							}}
							control={
								<CustomSwitch
									checked={checked}
									onChange={handleToggle}
									color="primary" 
								/>
							}
							label={
								<Stack 
								direction="row"
								spacing={2}
								sx={{
								justifyContent: "space-between",
								alignItems: "center",
								}}>
									<Typography variant="body1" sx={{marginLeft: '6px!important'}} >{t("sidebar.showRecommendedPostsOnFeed")}</Typography>
								</Stack>}
						/> 
					</ListItem>
					:
					<FormControlLabel
						variant="contained"
						className='SidebarButton'
						sx={{
							borderRadius: '12px!important',
							transition: '0s!important',
							marginTop:'8px!important',
							height:'40px!important',
							width: "inherit",
							backgroundColor: 'transparent!important',
							justifyContent: 'flex-start', 
							marginLeft: '2px',
							'&:hover': {
								backgroundColor: 'rgba(122, 122, 122, 0.06)!important',
							}, 
						}}
						control={
							<CustomSwitch
								checked={checked}
								onChange={handleToggle}
								color="primary" 
							/>
						}
						label={
							<Stack 
							direction="row"
							spacing={2}
							sx={{
							justifyContent: "space-between",
							alignItems: "center",
							}}>
								<Typography variant="body2" sx={{marginLeft: '2px!important'}} >{t("sidebar.showRecommendedPostsOnFeed")}</Typography>
								<Tooltip title={<>{t('sidebar.showRecommendedPostsOnFeedInfo')}</>}>
									<InfoIcon style={{ color:theme.palette.text.secondary, marginLeft: '20px' }}  fontSize='small'/>
								</Tooltip>
							</Stack>}
					/> 
				: null
			}
		</>
	)

}