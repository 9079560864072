import React, {useState, useEffect} from 'react';
import './AppUsersQuantityLimitComponent.css'
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import {  SHOW_HOME_SLOGAN_WIDTH } from '../../utils/Constants';

export default function AppUsersQuantityLimitComponent() {
	
	const { t } = useTranslation();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)	
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
		setWindowHeight(window.innerHeight)
	}	
	useEffect(() => {  
	// Anything in here is fired on component mount.	
	window.addEventListener('resize', setWindowDimensions);
	return () => {
		window.removeEventListener('resize', setWindowDimensions)
	}

	}, [])
	return (
		<>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				sx={{marginLeft: "25%", marginTop: "10%", marginRight: "25%", marginBottom: "10%"}}
			>
			<Typography variant="h2" sx={{textAlign: "center",
				marginTop: '22px' 
				,color:'#f8f8f8', 
				}}>	
					{t("appUsersQuantityLimit.body")}
				</Typography>
			</Stack>
		</>
	)

}