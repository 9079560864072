import React, {useState, useEffect} from 'react';
import './HomeSloganComponent.css'
import { useTranslation } from 'react-i18next';
import { SHOW_HOME_SLOGAN_WIDTH } from '../../utils/Constants';
import Grid from '@mui/material/Grid';
import logoWhite from '../../utils/logoWhite.svg'
import logoBlack from '../../utils/logoBlack.svg'
import Typography from '@mui/material/Typography';

export default function HomeSloganComponent(props) {
	const { t } = useTranslation();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const setWindowDimensions = () => {
	  setWindowWidth(window.innerWidth)
	}	
	useEffect(() => {  
		// Anything in here is fired on component mount.		

		const div = document.querySelector('.content');
		if (div) {
			div.classList.add('animate');
		}
		window.addEventListener('resize', setWindowDimensions);
		return () => {
		  	window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])
	return (
        <>
        	{windowWidth >=  SHOW_HOME_SLOGAN_WIDTH ? 
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundColor: (t) =>
					t.palette.mode === 'light' ? "#4b6ddb" : "#4b6ddb" ,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					overflow:'hidden!important'
				}}
				> 
                <div class="content">
                    <img src={logoBlack} alt="logo" class="logo" /> 
                    <Typography className='home-text' variant='h2'  color={"#303030"}>
                        {t("homeSloganComponent.slogan")}
                    </Typography>
					<div className="bubble-wrapper">
						<div className="bubble-container">
							{Array.from({ length: 8 }).map((_, index) => (
							<div key={index} className="bubble-wrapper-inner">
								<div key={index} className="bubble">
									<div className="text-line"></div>
									<div className="text-line"></div>
									<div className="text-line"></div>
								</div>
							</div>
							))}
						</div>
						<div className="inner-bubble-container">
							{Array.from({ length: 8 }).map((_, index) => (
								<div key={index} className="bubble-wrapper-inner">
									<div key={index} className="inner-bubble">
									<div className="text-line"></div>
									<div className="text-line"></div>
									<div className="text-line"></div>
									</div>
								</div>
							))}
						</div>
						<div className="inner-bubble-container2">
							{Array.from({ length: 8 }).map((_, index) => (
								<div key={index} className="bubble-wrapper-inner">
									<div key={index} className="inner-bubble2">
									<div className="text-line"></div>
									<div className="text-line"></div>
									<div className="text-line"></div>
									</div>
								</div>
							))}
						</div>
					</div>
                </div>
			</Grid>
			: null }
        </> 
		
	);
}