import './App.css';
import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {UserTopicHistoryPage, CreateTopicPage, ManageTopicsPage, ProfileConfigurationPage, MyFeedPage, SearchPage, DiscussionPage, ReplyOpeningPage, SignUpPage, SignInPage, RecoverPage, VerifyUserPage, DiscussionPostPage, CreateUserProfileErrorPage, 
	TermsPolicyPage, PrivacyPolicyPage, AcceptNewPoliciesPage, UserPersonalDataPage,
	UserPostMentionsPage,
	AuthActionPage} from './pages'
import { AuthProvider } from './contexts/AuthContext';
import { DocumentTitleProvider } from './contexts/DocumentTitleContext';

import '@fontsource/open-sans';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import GlobalStyles from "@mui/material/GlobalStyles";

import {PrivateRoute, PublicRoute, VerifiedRoute, VerificationPageRoute, AnonymousPublicRoute, CreateUserProfileErrorRoute} from './Routes';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { DocumentDescriptionProvider } from './contexts/DocumentDescriptionContext';
import { PoliciesAcceptedProvider } from './contexts/PoliciesAcceptedContext';
import AcceptNewPoliciesRoute from './Routes/AcceptNewPoliciesRoute';


export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
	
	const { t, i18n } = useTranslation();
	const [mode, setMode] = useState("light");

	useEffect(() => {
		// set site name meta tag
		const metaTag = document.createElement('meta');
		metaTag.setAttribute('property', 'og:site_name');
		metaTag.setAttribute('content', t('organizationData.name'));
		document.head.appendChild(metaTag);

		const lng = navigator.language.split('-')[0];
		i18n.changeLanguage(lng)

		var currentThemeMode = localStorage.getItem('themeMode')
		if (currentThemeMode === null || (window.location.pathname === "/flow/signup" || window.location.pathname === "/flow/signin" || window.location.pathname === "/flow/recover" || window.location.pathname === "/flow/policies")){
			// first login in this computer use Light Mode.
			setMode("light")
		} else {
			setMode(currentThemeMode)
		}
	}, [])

	
	const colorMode = React.useMemo(
	  () => ({
		toggleColorMode: () => {
			setMode((prevMode) => {
				localStorage.setItem("themeMode", prevMode === 'light' ? 'dark' : 'light')
				return prevMode ? prevMode === 'light' ? 'dark' : 'light' : 'light'
			});
		},
		lightColorMode: () => {
			setMode('light');
		}
	  }),
	  [],
	);

	
	const getDesignTokens = (mode, alwaysLightPages) =>({
		typography: {
			fontFamily: 'Open Sans, sans-serif',
			h0: {
				fontWeight: 600,
				fontSize: "1.35rem"
			},
			h1: {
				fontWeight: 600,
				fontSize: "1.22rem"
			},
			h2: {
				fontWeight: 400,
				fontSize: "1.19rem"
			},
			h3: {
				fontWeight: 600,
				fontSize: "1.03rem"
			},
			h4: {
				fontWeight: 400,
				fontSize: "1.03rem"
			},
			h5: {
				fontWeight: 600,
				fontSize: ".91rem"
			},
			h6: {
				fontWeight: 400,
				fontSize: "0.85rem"
			},
			subtitle1: {
				fontWeight: 400,
				fontSize: "0.8rem"
			},
			subtitle2: {
				fontWeight: 600,
				fontSize: "0.8rem"
			},
			body1: {
				fontWeight: 400,
				fontSize: "0.85rem"
			},
			body2: {
				fontWeight: 600,
				fontSize: "0.85rem"
			},
			caption:{
				fontWeight: 400,
				fontSize: "0.8rem",
				color: "#8e8e8e"
			},
			button: {
				fontWeight: 400,
			}, 
			list_subheader:{
				fontWeight: 500,
				fontSize: "0.8rem"
			} 
		},
			palette: {
				mode,
				...(mode === 'light' || alwaysLightPages === true
				? ({
					// palette values for dark mode
					manageTopics: {
						paper: "#fff",
						summary: "#fff",
						details: "#fff"
					},
					primary: {
						main:'#436efa'
					},
					secondary:{
						main: '#4345C6',
						icon:'#436efa'
					},
					sidebar:{
						primary: 'rgb(67, 110, 250, 0.65)',
						secondary:'#ffffff',
						buttonColor: '#444444'
					},
					divider: '#fff',
					background: {
						default: '#fefefe',
						paper: '#fff',
					},
					action: {
						active: 'rgba(0, 0, 0, 0.87)',
						hover: '#ebebeb',
						selected: '#888',
						disabled: '#888',
						disabledBackground: '#fafafa'
					},
					text: {
						primary: 'rgba(0, 0, 0, 0.92)',
						secondary: 'rgba(0, 0, 0, 0.68)',
						disabled: 'rgba(0, 0, 0, 0.48)'
					}
				})
				: ({
					// palette values for dark mode
					manageTopics: {
						paper: "#000",
						summary: "transparent",
						details: "transparent"
					},
					primary: {
						main:'rgba(255, 255, 255, 0.86)'
					},
					secondary:{
						main: 'rgba(255, 255, 255, 0.86)',
						icon: "rgba(255, 255, 255, 0.86)"
					},
					sidebar:{
						primary: '#333!important',
						secondary:'#333',
						buttonColor: '#333'
					},
					divider: '#333',
					background: {
						default: '#000',
						paper: '#000',
					},
					action: {
						active: 'rgba(255, 255, 255, 0.8)',
						hover: '#4d4d4d',
						selected: '#888',
						disabled: '#888',
						disabledBackground: '#fafafa'
					},
					text: {
						primary: 'rgba(255, 255, 255, 0.8)',
						secondary: 'rgba(255, 255, 255, 0.7)',
						disabled: 'rgba(255, 255, 255, 0.5)!important'
					}
					})
				)
			
		}
	});

	const alwaysLightPages = (window.location.pathname === "/flow/signup" || window.location.pathname === "/flow/signin" || window.location.pathname === "/flow/recover" || window.location.pathname === "/flow/policies") ? true : false

	const theme = createTheme(getDesignTokens(mode, alwaysLightPages));
	
	return (
		<div className="app">	
			<GlobalStyles
			styles={{
				body: { 
					backgroundColor: mode === 'light' || alwaysLightPages  ? "#fff" : "#000",
				},
				'.content-editable-text-box': {
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					color:  mode === "light" || alwaysLightPages ? "rgba(0, 0, 0, 0.92)!important" : "rgba(255, 255, 255, 0.8)!important",
				},
				'.editor-input': {
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					color:  mode === "light" || alwaysLightPages ? "rgba(0, 0, 0, 0.92)!important" : "rgba(255, 255, 255, 0.8)!important",
					borderRadius: '8px 8px 0px 0px',
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					border:  'none!important',
				},
				'.editor-input-div-button-div':{
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
				},
				'.editor-input-div': {
					borderRadius: '8px',
					borderTop:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333!important',
					borderRight:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333!important',
					borderLeft:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333!important',
				},
				'.editor-input:focus': {
					borderColor:  mode === "light" || alwaysLightPages ? "#436efa!important" : "rgba(255, 255, 255, 0.8)!important",
				},
				'.editor-input a': {
					color: mode === 'light' || alwaysLightPages  ? "#436efa!important" : "rgba(255, 255, 255, 0.95)!important"
				},
				'#postLink': {
					color: mode === 'light' || alwaysLightPages  ? "#436efa!important" : "rgba(255, 255, 255, 0.95)!important"
				},
				'#userLink': {
					color: mode === 'light' || alwaysLightPages  ? "#436efa!important" : "rgba(255, 255, 255, 0.95)!important"
				},
				'.SkewedFooter': {
					backgroundColor: mode === 'light' || alwaysLightPages  ? "#fff!important" : "#333!important"
				},
				'.SearchPaper1': {
					backgroundColor: mode === 'light' || alwaysLightPages  ? "#fff!important" : "#000!important",
					borderRight:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333!important',
					borderLeft:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333!important',
				},
				'.SearchPaper2': {
					backgroundColor: mode === 'light' || alwaysLightPages  ? "#fff!important" : "#000!important",
					border:  'none!important',
				},
				'.CustomCard1': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					borderRadius: "22px!important",
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #444!important',
				},
				'.DiscussionsDrawerButton': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#f8f8f8!important" : "#333!important",
					transition: '0s!important'
				}, 
				'.TopicDiscussionsDrawer': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#f8f8f8!important" : "#333!important",
				},
				'.ReportedPostCard': {
					backgroundColor: mode === "light" || alwaysLightPages ? "rgba(110, 144, 255, 0.25)!important" : "#222!important",
					borderRadius: "22px!important",
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '#444!important',
					border: "none!important",
					paddingTop: "20px!important",
					paddingBottom: "20px!important"
				},
				'.CustomCard2': {
					borderRadius: "12px!important",
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					boxSizing: "border-box",
					border: mode === "light" || alwaysLightPages ? "1px solid rgb(224, 224, 224)!important" : "1px solid #333!important",
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					boxShadow:"none"
				},
				'.MentionsList': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : "1px solid #333!important",
				},
				'.PostReferencedCard': {
					borderRadius: "22px!important",
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					boxSizing: "border-box",
					border: "none!important",
					backgroundColor: mode === "light" || alwaysLightPages ? "#f4f4f4!important" : "#222!important",
					boxShadow:"none"
				},
				'.RecommendedTitle': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#f8f8f8!important" : "#333!important"
				},
				'.ReplyDrawer': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#444!important",
				},
				'.RecommendedCard': {
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : "1px solid #333!important",
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					boxShadow:"none"
				},
				'.CustomCard3': {
					borderRadius: "22px!important",
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					border: "none!important",
					backgroundColor: mode === "light" || alwaysLightPages ? "#f8f8f8!important" : "#333!important",
					border:"none", boxShadow:"none"
				},
				'.RecentSearchbar': {
					backgroundColor: mode === "light" || alwaysLightPages ? "#f8f8f8!important" : "#333!important"
				},
				'.MuiBodyTextField': {
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#333!important",
					borderRadius: "4px",
					borderColor: "transparent!important"
				},
				'.MuiCreateTopicTextField': {
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					borderRadius: "8px!important",
					borderColor: "transparent!important",
					boxShadow: "none!important",
					WebkitBoxShadow: "none!important",
					MozBoxShadow: "none!important",
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333!important',
					'.MuiOutlinedInput-notchedOutline': {
						border:  mode === 'light' || alwaysLightPages  ? 'none!important' : 'none!important',
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							border:  mode === 'light' || alwaysLightPages  ? '1px solid #436efa!important' : '1px solid rgb(224, 224, 224)!important!important',
						},
					},
				},
				'.MuiInputBase-multiline': {
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					borderRadius: "8px!important",
					borderColor: "transparent!important"
				},
				'.MuiAutocomplete-inputRoot ': {
					backgroundColor:  mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					borderRadius: "8px!important",
					borderColor: "transparent!important"
				},
				'.MuiButton-contained': {
					padding: "4px!important",
					boxShadow: 'none!important',
					margin: "3px!important",
					borderRadius: "6px!important",
					textTransform: 'none!important',
					color:"#f8f8f8!important", 
					backgroundColor:  mode === "light" || alwaysLightPages ? "primary.main!important" : "#444!important"
				},
				'.LoadMoreButton':{
					boxShadow: 'none!important',
					textTransform: "none!important"
				},
				'.MuiButton-contained:disabled': {
					boxShadow: 'none!important',
					backgroundColor: mode === "light" || alwaysLightPages ? "#cfcfcf!important" : "#616161!important"
				},
				'.FlowButton': {
					padding: "4px!important",
					boxShadow: 'none!important',
					margin: "3px!important",
					borderRadius: "6px!important",
					textTransform: 'none!important',
					color:"#fff!important", 
					backgroundColor:  "rgba(256,256,256,0.43)!important",
					"&.Mui-disabled": {
						backgroundColor: "#646f91!important",
						color: "#c0c0c0"
					}
				},
				'.FlowButton:hover': {
					color:"#fff!important", 
					backgroundColor:  "rgba(256,256,256,0.5)!important",
				},
				'.HomePageTextField': {
					backgroundColor:"#f8f8f8", borderRadius: "4px",
					"& label.Mui-focused": {
						color: "#a0a6ba", 
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: "white", 
						},
						"&:hover fieldset": {
							borderColor: "white", 
						},
						"&.Mui-focused fieldset": {
							borderColor: "#a0a6ba", 
						},
					},
				},
				'.MuiButton-outlined': {
					boxShadow: 'none!important',
					padding: "4px!important",
					margin: "3px!important",
					textTransform: 'none!important',
					borderRadius: "6px!important",
				},
				'.NotificationsButtonTypography': {
					boxShadow: 'none!important',
					overflow: "hidden",
					textOverflow: "ellipsis",
					display: "-webkit-box",
					WebkitLineClamp: "1",
					marginBottom: "3px!important",
					WebkitBoxOrient: "vertical"
				},
				'.NotificationsButton': {
					boxShadow: 'none!important',
					textAlign: "left!important",
					marginBottom: "2px!important"
				},
				'.ReplyPostButton': {
					boxShadow: 'none!important',
					backgroundColor: mode === "light" || alwaysLightPages ? "rgba(75, 109, 219,0.4)!important" : "rgba(0,0,0,0.3)!important",
					position:"absolute",
					right: 0,
					top: 0,
					height: "100%",
				},
				'.ReplyButtonStickyCollapsibleInfoBar':{
					borderStartStartRadius: "0px!important",
					borderStartEndRadius: "0px!important",
					borderEndStartRadius: "24px!important", borderEndEndRadius: "24px!important"
				},
				'.ReplyPostButton:hover': {
					boxShadow: 'none!important',
					backgroundColor: mode === "light" || alwaysLightPages ? "rgba(75, 109, 219,0.5)!important" : "rgba(0,0,0,0.35)!important",
				},
				'.SidebarButton': {
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : '1px solid #333333!important',
				},
				'.ShareCardPaper':{
					boxShadow: 'none!important',
					backgroundColor: mode === "light" || alwaysLightPages ? "#f8f8f8!important" : "#222!important",
					borderRadius: '22px!important',
				},
				'.StickyCollapsibleInfoBar': {
					boxShadow: 'none!important',
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
					border:  mode === 'light' || alwaysLightPages  ? '1px solid rgb(224, 224, 224)!important' : "1px solid #333!important",
				},
				'.MuiCard-root': {
					boxShadow: 'none!important',
					border: mode === 'light' || alwaysLightPages ? "solid 1px #ececec" : "solid 1px #000"
				},
				'.MuiAccordion-root': {
					boxShadow: 'none!important',
					border: mode === 'light' || alwaysLightPages ? "solid 1px #ececec" : "solid 1px #333333"
				},
				'.MuiDivider-root':{
					borderBottom: mode === 'light' || alwaysLightPages ? "solid 1px #ececec!important" : "solid 1px #333333!important"
				},
				'.MuiPopover-paper':{
					boxShadow: mode === 'light' || alwaysLightPages ? "3px 6px 14px #e6e6e6!important" : "3px 6px 14px #292929!important"
				},
				'.MuiChip-root .MuiSvgIcon-root': {
					color: mode === 'light' || alwaysLightPages ? 'rgba(0, 0, 0, 0.38)!important' : 'rgba(255, 255, 255, 0.5)!important'
				},
				'.MuiTypography-root':{
					color: mode === 'light' || alwaysLightPages ? 'rgba(0, 0, 0, 0.89)' : 'rgba(255, 255, 255, 0.98)'
				},
				'.MuiTypography-list_subheader':{
					color: mode === 'light' || alwaysLightPages ? '#7d7d7d' : '#888'
				},
				'.TutorialPopover':{
					borderRadius: '12px!important',
					minHeight:'150px',
					backgroundColor: mode === "light" || alwaysLightPages ? "#fff!important" : "#000!important",
				}
			}}
			/>
			<ColorModeContext.Provider value={colorMode}>
				<ThemeProvider theme={theme}>
					<Router>
						<DocumentTitleProvider>
							<DocumentDescriptionProvider>
								<AuthProvider>
									<PoliciesAcceptedProvider>
										<Routes>
											{/* Private Routes (without user verification)*/}
											<Route exact path="/" element={<PrivateRoute/>}><Route exact path="/" element={<MyFeedPage/>}/></Route>
											<Route exact path="/page/profile" element={<PrivateRoute/>}><Route exact path="/page/profile" element={<ProfileConfigurationPage/>}/></Route>
											<Route exact path="/page/manage" element={<PrivateRoute/>}><Route exact path="/page/manage" element={<ManageTopicsPage/>}/></Route>
											<Route exact path="/page/mentions" element={<PrivateRoute/>}><Route exact path="/page/mentions" element={<UserPostMentionsPage/>}/></Route>
											<Route exact path="/page/userpersonaldata" element={<PrivateRoute/>}><Route exact path="/page/userpersonaldata" element={<UserPersonalDataPage/>}/></Route>
											
											{/* Specific Route to prevent verified user to access verification page */}
											<Route exact path="/page/verifyuser" element={<VerificationPageRoute/>}><Route exact path="/page/verifyuser" element={<VerifyUserPage/>}/></Route>

											{/* Routes that need verification (and are private by default) */}
											<Route exact path="/page/newtopic" element={<VerifiedRoute/>}><Route exact path="/page/newtopic" element={<CreateTopicPage/>}/></Route>						

											{/* Public Routes */}
											<Route exact  path="/flow/signup" element={<PublicRoute/>}><Route exact path="/flow/signup" element={<SignUpPage/>}/></Route>
											<Route exact  path="/flow/signin" element={<PublicRoute/>}><Route exact path="/flow/signin" element={<SignInPage/>}/></Route>
											<Route exact  path="/flow/recover" element={<PublicRoute/>}><Route exact path="/flow/recover" element={<RecoverPage/>}/></Route>

											<Route exact path="/page/results" element={<AnonymousPublicRoute/>}><Route exact path="/page/results" element={<SearchPage/>}/></Route>
											
											<Route exact path="/:username" element={<AnonymousPublicRoute/>}><Route exact path="/:username" element={<UserTopicHistoryPage />}/></Route>
											<Route exact path="/topic/:topicId/discussion/:discussionId" element={<AnonymousPublicRoute/>}><Route exact path="/topic/:topicId/discussion/:discussionId" element={<DiscussionPage />}/></Route>
											<Route exact path="/topic/:topicId/discussion/:discussionId/post/:postId" element={<AnonymousPublicRoute/>}><Route exact path="/topic/:topicId/discussion/:discussionId/post/:postId" element={<DiscussionPostPage />}/></Route>
											<Route exact path="/topic/:topicId" element={<AnonymousPublicRoute/>}><Route exact path="/topic/:topicId" element={<ReplyOpeningPage/>}/></Route>

											<Route exact path="/flow/terms" element={<TermsPolicyPage/>}/> {/* Terms Policy is an always public page*/}
											<Route exact path="/flow/privacy" element={<PrivacyPolicyPage/>}/> {/* Privacy Policy is an always public page*/}
											<Route exact  path="/flow/newpolicies" element={<AcceptNewPoliciesRoute/>}><Route exact path="/flow/newpolicies" element={<AcceptNewPoliciesPage/>}/></Route>

											<Route exact path="/error/userprofile" element={<CreateUserProfileErrorRoute/>}><Route exact path="/error/userprofile" element={<CreateUserProfileErrorPage/>}/></Route>

											<Route exact path="/page/authaction" element={<AuthActionPage/>}/>
											
											<Route path="*" element={<Navigate to="/" />} /> {/* For undefined uri */}								
										</Routes>
									</PoliciesAcceptedProvider>
								</AuthProvider>
							</DocumentDescriptionProvider>
						</DocumentTitleProvider>
					</Router>	
				</ThemeProvider>
			</ColorModeContext.Provider>			
		</div>
	)
}

export default App;
