import React, {useState, useEffect} from 'react';
import './RecentDiscussionsListComponent.css'

import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import DiscussionPopoverCardComponent from '../DiscussionPopoverCardComponent';
import { deleteRecentDiscussion, getRecentDiscussions } from '../../firebaseFunctions/spannerFunctions/recentDiscussionsFunctions';

export default function RecentDiscussionsListComponent() {
	const { t } = useTranslation();

	
	const [discussionList, setDiscussionsList]  = useState([]);
	const [searchedDiscussionsList, setSearchedDiscussionsList] = useState([])

	useEffect(() => {  
		// Anything in here is fired on component mount.	
		getRecentDiscussions().then(list => {
			setDiscussionsList(list)
			setSearchedDiscussionsList(list)
		})

		return () => {
		};
    }, [])

	const handleRecentSearchedChange = (event) => {	
		if (event.target.value === ""){
			setSearchedDiscussionsList(discussionList)
		} else {
			var newSearchedDiscussionsList = discussionList.filter(topic => {				
				return topic.topic.title.toLowerCase().includes(event.target.value.toLowerCase())
			});
			setSearchedDiscussionsList(newSearchedDiscussionsList)
		}
	};

	const handleDeleteRecentDiscussion = (topicId, discussionId) => {
		const newDiscussionList = discussionList.filter(discussion => {
			return discussion.topic.topicId !== topicId && discussion.discussion.discussionId !== discussionId;
		})
		const newSearchedDiscussionList = searchedDiscussionsList.filter(discussion => {
			return discussion.topic.topicId !== topicId && discussion.discussion.discussionId !== discussionId;
		})
		setDiscussionsList(newDiscussionList);
		setSearchedDiscussionsList(newSearchedDiscussionList)
		deleteRecentDiscussion(topicId, discussionId)
	}
	return (
		<>
			<Card 
				sx={{
					height: "calc(100vh - 400px)",
					position: 'relative',
					width:'inherit',
					overflow: 'auto',
					marginLeft:'3px!important',
					marginTop:'12px!important',
					'& ul': { padding: 0 }
				}} className="CustomCard2" >	
				<ListSubheader  className='RecentSearchbar' sx={{height: "auto", width: "auto"}}>
					<TextField
						variant="standard"
						autoComplete='off'
						spellCheck={false}
						onChange={e => handleRecentSearchedChange(e)}
						focused
						sx={{ padding: "8px", borderRadius: "3px", width: "100%", margin: 0}}
						InputProps={{
							disableUnderline: true,
							endAdornment:(
								<InputAdornment position="end">
									<Icon
										sx={{
											ml: "6px!important",
											"&.MuiButtonBase-root:hover": {
												bgcolor: "transparent"
											}
										}}
										type="submit"
										aria-label="search user"
										edge="end">
										<SearchIcon />
									</Icon>
								</InputAdornment>
							)
						}}
						placeholder={t("sidebar.searchRecentDiscussions")}
					/>
				</ListSubheader>

				{searchedDiscussionsList !== null && searchedDiscussionsList.length > 0 ? searchedDiscussionsList.map((discussion) => (
					<>
						<Stack direction="row" spacing={0}>
							<DiscussionPopoverCardComponent topic={discussion.topic} discussion={discussion.discussion} recent={true}/>
							<IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'transparent!important' }} onClick={() => handleDeleteRecentDiscussion(discussion.topic.topicId, discussion.discussion.discussionId)}>
								<ClearIcon fontSize="small"/>
							</IconButton> 
						</Stack>
						<Divider/>
					</>
				)) 
				:
					<Typography variant='caption' sx={{margin:"6px"}}>{t("sidebar.noRecentDiscussions")}</Typography> 
				}
			</Card>
		</>
	)
}

