import React, {useState, useEffect} from 'react';
import './SidebarComponent.css'
import {AvatarPreviewComponent, FeedPostsCustomizationSwitchComponent, RecentDiscussionsListComponent, RecommendedUsersComponent} from '../../components'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import CreateIcon from '@mui/icons-material/Create';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ListIcon from '@mui/icons-material/List';
import { auth } from "../../firebase";
import { AVATAR_SMALL_SIZE, MAX_LAPTOP_WIDTH, MAX_MOBILE_WIDTH, MIN_SIDEBAR_WIDTH, NAVBAR_HEIGHT, SIDEBAR_WIDTH } from '../../utils/Constants';

import { getCurrentUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function SidebarComponent(props) {
	const { t } = useTranslation();
	const theme = useTheme();

	const navigate = useNavigate() 
	
	const handleNavigateToCreateTopic = () => {
		localStorage.setItem('showCreateTopicTutorial', JSON.stringify(false));
		setCreateTopicTutorialOpen(false);
		navigate("/page/newtopic")
	}
	const handleNavigateToManageTopics = () => {
		navigate("/page/manage")
	}
	const handleNavigateToUserMentions = () => {
		navigate("/page/mentions")
	}
	
	const [user, setUser]  = useState();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const setWindowDimensions = () => {
	  setWindowWidth(window.innerWidth)
	}	

	const [createTopicTutorialOpen, setCreateTopicTutorialOpen] = useState(false);
	
	const handleCreateTopicTutorialButtonClick = () => {
		localStorage.setItem('showCreateTopicTutorial', JSON.stringify(false));
		setCreateTopicTutorialOpen(false);
	}

	useEffect(() => {  
		// Anything in here is fired on component mount.
		if (auth.currentUser) {
			getCurrentUserProfile().then((res) => {
				setUser(res)
			})
		}
		
		const showCreateTopicTutorial = localStorage.getItem('showCreateTopicTutorial');
		if (JSON.parse(showCreateTopicTutorial) === true || showCreateTopicTutorial === null) {
			setCreateTopicTutorialOpen(true);
		}

		window.addEventListener('resize', setWindowDimensions);
		return () => {
		  	window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])

	return (
		<>
			{windowWidth < MAX_MOBILE_WIDTH ? 	
				<></>
			:
			<Grid item sx={{
				width: windowWidth < MAX_LAPTOP_WIDTH ? windowWidth * 0.25 +"px" : windowWidth * 0.3 +"px"
				}}>
				{
					user && auth.currentUser &&							
						<Stack  sx={{
							marginTop: '0px', 
							position: 'fixed', width: "inherit", height: 'calc(100vh - ' + NAVBAR_HEIGHT + ')'
							}}
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-end">

							<Stack direction='column'
							sx={{width: windowWidth < MAX_LAPTOP_WIDTH ? MIN_SIDEBAR_WIDTH : SIDEBAR_WIDTH, 
								justifyContent: "flex-start",
								alignItems: "center",
								height: 'calc(100vh - ' + NAVBAR_HEIGHT + ')' 
								}}
								>
								<Stack 
									direction="row"
									spacing={0.5}
									sx={{
										justifyContent: "flex-start",
										alignItems: "center",
										width: "inherit",
										height: AVATAR_SMALL_SIZE *1.3 + 'px',
										marginTop: '6px',
										backgroundColor: 'transparent!important',
										transition: '0s!important',
										borderEndEndRadius: '12px',
										borderEndStartRadius: '12px'
									}} >
									{user.avatarSmallUrl !== "" ?  												
										<AvatarPreviewComponent avatar={user.avatarMediumUrl}
											avatarBig={user.avatarBigUrl}
											size={AVATAR_SMALL_SIZE + "px"}
											/>
									:
										<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} alt="avatar">
											{user.username[0].toUpperCase()}
										</Avatar>
									}
									<Typography noWrap align='start' style={{ 
												color: theme.palette.text.primary, // Change the text color on hover
												marginTop: '4px', wordWrap: "break-word" }} display='block' variant='body2'>
										<Link component={RouterLink} to={"/" + user.username} color={theme.palette.text.primary} underline="hover">
											{user.username}
										</Link>	
									</Typography>
								</Stack>
								<Stack direction='column' sx={{width: 'inherit'}}>
									
									<div style={{ position: 'relative', width:'100%'}}>
										<Button
											variant="contained"
											className='SidebarButton'
											onClick={handleNavigateToCreateTopic}
											sx={{
												borderRadius: '12px!important',
												transition: '0s!important',
												marginTop:'8px!important',
												height:'40px!important',
												width: "inherit",
												backgroundColor: 'transparent!important',
												justifyContent: 'flex-start', 
												padding: '20px 0px!important',
												position: 'relative',
												'&:hover': {
													backgroundColor: 'rgba(122, 122, 122, 0.06)!important',
												}, 
											}}
											startIcon={<CreateIcon style={{  color:theme.palette.text.primary, marginLeft: '20px' }}  fontSize='small'/>}
										>
											<Typography variant="body2">{t("sidebar.createTopic")}</Typography>
										</Button>
										{ createTopicTutorialOpen === true ?
											<Box className='TutorialPopover' sx={{boxShadow: 3, 
												position: 'absolute',
												top: '48px!important', 
												zIndex: '2!important',  
												marginLeft:'3px!important',
												width: '100%!important',
												minHeight: '10px!important'}}>
												<Typography variant='h1' sx={{ p: 1 }}>{t("sidebar.createTopic")}</Typography>
												<Typography variant='body1' sx={{ p: 1 }}>{t("sidebar.createTopicTutorial")}</Typography>
												<Button
													variant="contained"
													onClick={handleCreateTopicTutorialButtonClick}
													sx={{
														float: 'right',
														margin: '12px!important',
														padding: '8px!important',
													}}
												>
													<Typography variant="body1" color='white!important'>{t("sidebar.dontShowCreateTopicTutorial")}</Typography>
												</Button>
											</Box> : null
										}
									</div>
									<Button
										variant="contained"
										className='SidebarButton'
										onClick={handleNavigateToManageTopics}
										sx={{
											borderRadius: '12px!important',
											transition: '0s!important',
											marginTop:'8px!important',
											height:'40px!important',
											width: "inherit",
											backgroundColor: 'transparent!important',
											justifyContent: 'flex-start', 
											padding: '20px 0px!important',
											'&:hover': {
												backgroundColor: 'rgba(122, 122, 122, 0.06)!important',
											}, 
										}}
										startIcon={<ListIcon style={{  color:theme.palette.text.primary, marginLeft: '20px' }}  fontSize='small'/>}
									>
										<Typography variant="body2">{t("sidebar.manageTopics")}</Typography>
									</Button>
									<Button
										variant="contained"
										className='SidebarButton'
										onClick={handleNavigateToUserMentions}
										sx={{
											borderRadius: '12px!important',
											transition: '0s!important',
											marginTop:'8px!important',
											height:'40px!important',
											width: "inherit",
											backgroundColor: 'transparent!important',
											justifyContent: 'flex-start', 
											padding: '20px 0px!important',
											'&:hover': {
												backgroundColor: 'rgba(122, 122, 122, 0.06)!important',
											}, 
										}}
										startIcon={<AlternateEmailIcon style={{ color:theme.palette.text.primary, marginLeft: '20px' }}  fontSize='small'/>}
									>
										<Typography variant="body2" >{t("sidebar.mentions")}</Typography>
									</Button>
									<RecommendedUsersComponent />
									<FeedPostsCustomizationSwitchComponent mobile={false} />
									<RecentDiscussionsListComponent />
								</Stack>
							</Stack>
						</Stack>
				}
			</Grid>
			}
		</>
	)
}

