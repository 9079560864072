import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {  SHOW_HOME_SLOGAN_WIDTH } from '../../utils/Constants';
function RecaptchaNoticeComponent() {
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)	
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
		setWindowHeight(window.innerHeight)
	}	
	useEffect(() => {  
	// Anything in here is fired on component mount.	
	window.addEventListener('resize', setWindowDimensions);
	return () => {
		window.removeEventListener('resize', setWindowDimensions)
	}

	}, [])
	return (
		<>
			<Typography variant="caption" sx={{
				color:windowWidth < SHOW_HOME_SLOGAN_WIDTH ? 'white' : 'inherit'
			}}>
				{t('legals.recaptchaDisclaimer.part1')} 
				<a href="https://policies.google.com/privacy" style={{color:windowWidth < SHOW_HOME_SLOGAN_WIDTH ? 'white' : 'inherit'}} underline="none" >{t('legals.recaptchaDisclaimer.privacyPolicy')}</a>{t('legals.recaptchaDisclaimer.part2')} 
				<a href="https://policies.google.com/terms" style={{color:windowWidth < SHOW_HOME_SLOGAN_WIDTH ? 'white' : 'inherit'}} underline="none" >{t('legals.recaptchaDisclaimer.terms')}</a>{t('legals.recaptchaDisclaimer.part3')}
			</Typography>
		</>
	);
  }


export default RecaptchaNoticeComponent;