
import { httpsCallable } from "firebase/functions";
import {  functions } from "../../firebase";
import { convertBackendResponseKeysToFrontEndFormat } from "../../utils/ConvertObjectToFirstCharLowerCase";
export async function getRecommendedUsers(lastUsername){
	const getrecommendeduserslistfromcloudspanner = httpsCallable(functions, 'getrecommendeduserslistfromcloudspanner');
	return await getrecommendeduserslistfromcloudspanner({lastUsername: lastUsername})
	.then((result) => {
		const data = result.data.data;

		const dataInLocalFormat = data !== null ? data.map((row) => { return convertBackendResponseKeysToFrontEndFormat(row)}) : []
		return {data: dataInLocalFormat, isLastUser: result.data.isLastUser}
	})
	.catch((error) => {
	});
}