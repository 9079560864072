import React from 'react';
import './UserPopoverCardComponent.css'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { auth } from "../../firebase";
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { AVATAR_SMALL_SIZE } from '../../utils/Constants';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { FeedDate } from '../../utils/Dates';
import { useTranslation } from 'react-i18next';
import { ShortenNumber } from '../../utils/NumberShortener';

export default function UserPopoverCardComponent(props) {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
	  setAnchorEl(event.currentTarget);
	};
  
	const handlePopoverClose = () => {
	  setAnchorEl(null);
	};
  
	const open = Boolean(anchorEl);
  
	return (
		<>
		{
			props.user ?
			<>
				{props.type === "post" ?
					<a id='userLink' href={'/' + props.user.username} target="_blank" rel="noopener noreferrer"
						aria-owns={open ? 'mouse-over-popover' : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}> 	
						{`@${props.user.username}`}
					</a>
				:
					<Typography
						aria-owns={open ? 'mouse-over-popover' : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
						sx={{marginLeft: "-10px"}}
					>
						{
								props.type === "share" ?
									props.place === "from" ?
										<Link component={RouterLink} to={"/" + props.user.username} color="inherit" underline="hover">									
											<Typography variant="body1">{auth.currentUser && props.uid === auth.currentUser.uid ? t("userPopoverCard.you") :  props.user.username}{" " + t("userPopoverCard.sharedAPost")}</Typography>
										</Link> 
									:	
										<Link component={RouterLink} to={"/" + props.user.username} color="inherit" underline="hover">
											<Typography variant="body1">
												{props.user.username}
											</Typography>
										</Link>
								:
									<Link component={RouterLink} to={"/" + props.user.username} color="inherit" underline="hover">
										<Typography variant="body1">
											{props.user.username}
										</Typography>
									</Link>
						}
					</Typography>
				}
				<Popover
					id="mouse-over-popover"
					sx={{
					pointerEvents: 'none',
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
					}}
					transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<Card sx={{ width: "320px", maxWidth: "90vw"}}>
						<CardHeader sx={{marginBottom:0}}
							avatar={
								<Avatar src={props.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE}} aria-label="avatar">
									<Typography variant="body1" color={"white"}>{
										props.user.username ? 
											props.user.username[0].toUpperCase()
										: null}</Typography>
								</Avatar>
							}
							title={
								<>
									<Typography  variant="body1" color="text.primary">
										{props.user.username}
									</Typography>
									{
										props.user.timestamp ?
											<Typography sx={{fontStyle:'italic'}} variant="caption">
												{t("userPopoverCard.joined") + ": " + FeedDate(props.user.timestamp)}
											</Typography>
											: null
									}
								</>
							}
						/>
						<CardContent  sx={{padding:0, margin:0}}>
							<Typography sx={{paddingLeft:"16px", paddingRight:"16px", 
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitLineClamp: "3",
								WebkitBoxOrient: "vertical"}} variant="subtitle1" color="text.primary">
								{props.user.bio}
							</Typography>
							<Divider sx={{marginTop:"16px"}} variant="middle" flexItem />
							<Grid direction="row" container 
								justifyContent="space-evenly"
								alignItems="center" spacing={0} sx={{textAlign: 'center', padding:0, marginTop:"16px"}}>
								<Grid item>
									<Typography variant="body1" color="text.secondary">
										{t("following")}
									</Typography>
									<Typography  variant="subtitle1" color="text.secondary">
										{ShortenNumber(props.user.follows)}
									</Typography>
								</Grid>
								<Divider orientation="vertical" variant="middle" flexItem />
								<Grid item >
									<Typography variant="body1" color="text.secondary">
										{t("followers")}
									</Typography>
									<Typography variant="subtitle1" color="text.secondary">
										{ShortenNumber(props.user.followers)}
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Popover>
				</>
			: 
				props.username === null ?
					<Typography variant="body1" sx={{fontStyle:"italic", fontWeight:'bold'}}>
						{t("userPopoverCard.usernameNotFound")}
					</Typography>
					:
					<a id='userLink' href={'/' + props.username} target="_blank" rel="noopener noreferrer"> 	
						{`@${props.username}`}
					</a>
		}
		</>
	)

}