import { auth, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { convertBackendResponseKeysToFrontEndFormat } from "../../utils/ConvertObjectToFirstCharLowerCase";
import axios from 'axios';
import { API_BASE_URL } from "../../utils/Constants";

export async function createUser(username){
	const createuserprofileincloudspanner = httpsCallable(functions, 'createuserprofileincloudspanner');
	return await createuserprofileincloudspanner({ username: username })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function isUserAllowedToSignUp(){
	const isuserallowedtosignup = httpsCallable(functions, 'isuserallowedtosignup');
	return await isuserallowedtosignup()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
  
export const updateUserProfile = (username, bio, bioUnchanged, avatar) => {
	return new Promise((resolve, reject) => {
		var profileData = { success: false, error: false, linksPerPostExceeded: false, usernamesMentionsExceeded: false, webRiskThreat: false, userBlockedDueToWebRiskThreat: false }

		const updateuserprofileincloudspanner = httpsCallable(functions, 'updateuserprofileincloudspanner');
	  	//get user profile
		getCurrentUserProfile().then(async (user) => { 
			if (user) {
				profileData.username = user.username
				profileData.avatarBigUrl = user.avatarBigUrl
				profileData.avatarMediumUrl = user.avatarMediumUrl
				profileData.avatarSmallUrl = user.avatarSmallUrl
				bio ? profileData.bio = bio : profileData.bio = user.bio.toString()
				if(avatar) {
					//upload avatar to check
					try {
						const idToken = await auth.currentUser.getIdToken()
						const res = await axios.post(
							`${API_BASE_URL}/changeuseravatarinfirebasestorage`,
							{ image: avatar },
							{
								headers: {
									Authorization: `Bearer ${idToken}`,
									'Content-Type': 'application/json',
								},
							}
						);    
						if (res.data.maxAvatarUpdatesReached === false) {
							if (res.data.error === true || res.data.explicit === true || res.data.fileExceedsSizeLimit === true) {
								if (res.data.error === true){
									profileData.avatarError = true
									profileData.avatarExplicit = false
									profileData.usernameTaken = false	
									profileData.avatarExceedsSizeLimit = false
									profileData.maxAvatarUpdatesReached = false
								} else {
									if(res.data.explicit === true) {
										profileData.avatarError = false
										profileData.avatarExplicit = true
										profileData.usernameTaken = false
										profileData.avatarExceedsSizeLimit = false
										profileData.maxAvatarUpdatesReached = false
									}	else {
										profileData.avatarError = false
										profileData.avatarExplicit = false
										profileData.usernameTaken = false
										profileData.avatarExceedsSizeLimit = true
										profileData.maxAvatarUpdatesReached = false
										
									}
								}
							}
							resolve(profileData)
						} else {
							profileData.avatarError = false
							profileData.avatarExplicit = false
							profileData.usernameTaken = false
							profileData.avatarExceedsSizeLimit = false
							profileData.maxAvatarUpdatesReached = true
							resolve(profileData)
						}
					} catch(error) {
						console.error('Error sending data');
					}
				} 			
				
				if (bioUnchanged === false || username !== "") {

					profileData.usernameTaken = user.username === username ? true : false
					if (username) {
						profileData.username = username
					} 
					if (profileData.usernameTaken) {
						profileData.avatarError = false
						profileData.avatarExplicit = false
						profileData.usernameTaken = true
						profileData.avatarExceedsSizeLimit = false
						resolve(profileData)	
					} else {
	
						updateuserprofileincloudspanner({username: username, bio: bio, bioUnchanged: bioUnchanged}).then((profileUpdate) => {
							profileData.avatarError = false
							profileData.avatarExplicit = false
							profileData.avatarExceedsSizeLimit = false
							if (profileUpdate.data.success) {
								profileData.usernameTaken = false	
							} 
							if (profileUpdate.data.usernameTaken){
								profileData.usernameTaken = true
							}
							profileData.success = profileUpdate.data.success
							profileData.error = profileUpdate.data.error
							profileData.linksPerPostExceeded = profileUpdate.data.linksPerPostExceeded
							profileData.usernamesMentionsExceeded = profileUpdate.data.usernamesMentionsExceeded
							profileData.webRiskThreat = profileUpdate.data.webRiskThreat
							profileData.userBlockedDueToWebRiskThreat = profileUpdate.data.userBlockedDueToWebRiskThreat
							resolve(profileData)	
						})	
					}
				}
				
			  } else {
			  }	
		  })
	})
}



export async function getCurrentUserProfile() {
	
	const getcurrentuserprofilefromcloudspanner = httpsCallable(functions, 'getcurrentuserprofilefromcloudspanner');
	return await getcurrentuserprofilefromcloudspanner()
	.then((result) => {
		const data = result.data;
		
		const dataInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data)
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}

export async function getUserProfileFromUsername(username) {
	const getuserprofilefromusernamefromcloudspanner = httpsCallable(functions, 'getuserprofilefromusernamefromcloudspanner');
	return await getuserprofilefromusernamefromcloudspanner({ username: username })
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data)
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}

export async function getUserListToInviteBySearchedUsername(usernameSearched) {
	
	const getuserlisttoinvitebysearchedusernamefromcloudspanner = httpsCallable(functions, 'getuserlisttoinvitebysearchedusernamefromcloudspanner');
	return await getuserlisttoinvitebysearchedusernamefromcloudspanner({ usernameSearched: usernameSearched })
	.then((result) => {
		const data = result.data;
		

		const dataInLocalFormat = result.data !== null ? data.map((row) => { return convertBackendResponseKeysToFrontEndFormat(row)}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}
export async function updateUserCounters(userId){
	
	const updateusercountersfromcloudspanner = httpsCallable(functions, 'updateusercountersfromcloudspanner');
	return await updateusercountersfromcloudspanner({ userId: userId })
	.then((result) => {
		const data = result.data
		return 
	})
	.catch((error) => {
	});
}

export async function getUserIdByUsername(username){
	const getuseridbyusernamefromcloudspanner = httpsCallable(functions, 'getuseridbyusernamefromcloudspanner');
	return await getuseridbyusernamefromcloudspanner({ username: username })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}