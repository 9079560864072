import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaEnterpriseProvider  } from "firebase/app-check";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCH6M8npfaI2eCb2-ekG96x9wtD1jFbk00",
	authDomain: "intergraphy-production-474e1.firebaseapp.com",
	projectId: "intergraphy-production-474e1",
	storageBucket: "intergraphy-production-474e1.firebasestorage.app",
	messagingSenderId: "319028127934",
	appId: "1:319028127934:web:65637849afc5faf151d6ef",
	measurementId: "G-FX5Y8LV162"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/// Custom App Check Provider
export const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider('6LfwCYsqAAAAADcLsC23AHpFMHl2UAlcIo6dNXOO'),
	isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});


export const auth = getAuth(app)
auth.useDeviceLanguage() // set device language for firebase emails
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);