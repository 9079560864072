import React, {useState} from 'react';
import './FollowListModalComponent.css'
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { auth } from "../../firebase";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteUserFollowedNotifications, getFollowersFromCloudSpanner, getFollowsFromCloudSpanner } from '../../firebaseFunctions/spannerFunctions/followerFunctions';
import { AVATAR_SMALL_SIZE } from '../../utils/Constants';
import {AvatarPreviewComponent} from '../../components'
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { followUser, isUserFollowed, unfollowUser } from '../../firebaseFunctions/spannerFunctions/followerFunctions';

function FollowListItemComponent(props) {
	const { t } = useTranslation();
	const index = props.index;
	const user = props.user;
	return (
		<>
			<ListItemAvatar>
				{user.avatarSmallUrl !== "" ?  														
					<AvatarPreviewComponent avatar={user.avatarSmallUrl}
						avatarBig={user.avatarBigUrl}
						size={AVATAR_SMALL_SIZE + "px"}
						/>
				:
					<Avatar sx={{ color: "white", width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE }} alt="avatar">
						{user.username[0].toUpperCase()}
					</Avatar>
				}
			</ListItemAvatar>
			<ListItemText id={user.userId} 
				sx={{flexShrink:1}}
				primary={
					<Typography style={{ width: 'inherit',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis' }} display='block' variant='body1'>
						<Link reloadDocument component={RouterLink} to={"/" + user.username} color="inherit" underline="none">
							<>
								{user.username}
							</>
						</Link>	
					</Typography>
				} 
			/>
			{user.userId !== auth.currentUser.uid && user.following !== null ? 
			<>
				<Button key={user.userId} variant={user.following ? "contained": "outlined"} 
					sx={{
						borderRadius: 5,
						padding: '4px',
						fontSize: '10.5px',
						marginRight:'12px'}} 
					disabled={user.loading || props.followingsLimit} onClick={(e) => props.handleFollowFunction(e, user.userId, user.following, index)}>{user.following ? t("unfollow") : t("follow")}</Button>
			</> : null}
		</>
	)
}

export default function FollowListModalComponent(props) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true)
	const [loadingFollowFunction, setLoadingFollowFunction] = useState(false)
	const [lastUsername, setLastUsername] = useState(null)
	const [users, setUsers] = useState([])
	const [followingsLimit, setFollowingLimit] = useState(false)
	const [ usernameSearched, setUsernameSearched] = useState("")
	const [loadingMoreUsers ,setLoadingMoreUsers] = useState(false)

	const [isLastUser, setIsLastUser] = useState(false)
	const navigate = useNavigate()	

	async function getUsersFunction() {
		setLoading(true)
		if (props.type === "following") {
			// fetch which users this user follows
			getFollowsFromCloudSpanner(props.userId, lastUsername, usernameSearched).then((res) => {
				setIsLastUser(res.isLastUser)
				res.data.forEach(user => {								
					isUserFollowed(user.userId).then((res2) => {
						const value = {...user, following: res2, loading:false, userId: user.userId}
						setUsers((prevUsers) => [ ...prevUsers, value ])
						setLastUsername(user.username)
					})
				})
				setLoading(false)
			})
		} else if (props.type === "followers") {
			// fetch who follows this user
			getFollowersFromCloudSpanner(props.userId, lastUsername, usernameSearched).then((res) => {
				setIsLastUser(res.isLastUser)
				res.data.forEach(user => {					
					isUserFollowed(user.userId).then((res2) => {
						const value = {...user, following: res2, loading:false, userId: user.userId}
						setUsers((prevUsers) => [ ...prevUsers, value ])
						setLastUsername(user.username)
					})
				})
				setLoading(false)
			})
		}
	}


	const handleClickOpen = () => {
		setOpen(true);
		if (!loadingFollowFunction) {
			getUsersFunction()
		} else {
			setLoading(true)
		}
	};
  
	const handleClose = (value) => {
	  setOpen(false);
	  setUsernameSearched("")
	  setUsers([])
	  setLastUsername(null)
	};

	const handleUsernameSearchedChange = (event) => {
		setUsernameSearched(event.target.value)  
	};
	  
	async function handleUsernameSearch(event) {
		event.preventDefault() 
		if (usernameSearched !== "") {
			setUsers([])
			setLastUsername(null)
			
			getUsersFunction()
		} else {
			setUsernameSearched("")
			setUsers([])
			setLastUsername(null)
			
			getUsersFunction()
		}
	}

	async function handleFollowFunction(e, userId, following, index) {
		e.preventDefault()
		setLoadingFollowFunction(true)
		if (auth.currentUser.emailVerified) {

			var newArr = [...users]
			newArr[index].loading = true
			setUsers(newArr)
	
			if (following === true){
				const unfollowSuccess = await unfollowUser(userId)
				if (unfollowSuccess === true){
					var newArr2 = [...users]
					newArr2[index].following = false
					setUsers(newArr2)
				}
			} 
			if (following === false){
				const followingValue = await followUser(userId)
				
				if (followingValue.res === true){
					const followedUser = followingValue.followedUser
					var newArr3 = [...users]
					newArr3[index].following = true
					setUsers(newArr3)
					deleteUserFollowedNotifications(followedUser)
				}
	
				setFollowingLimit(followingValue.followingLimit)
			}
	
			var newArr4 = [...users]
			newArr4[index].loading = false
			setUsers(newArr4)
			setLoadingFollowFunction(false)
			setLoading(false)
		} else {
			navigate('/page/verifyuser')
		}
	}

	return (
	  <div>
		
		<Snackbar open={followingsLimit} autoHideDuration={6000}>
			<Alert severity='error' sx={{ width: '100%' }}>{t("followListModalComponent.followingLimit")}</Alert>
		</Snackbar>
		<Button sx={{color: "text.primary", "&:hover": {color: "text.primary", backgroundColor: "transparent"}}} variant="text" onClick={handleClickOpen}>
			<Grid container direction="column">
				<Grid item>
					<Typography variant="body1" sx={{textTransform: 'none'}} color="inherit">
						{props.type === 'following' ? 
							t("following")
						: props.type === "followers" ? 
							t("followers")
							: null}
					</Typography>
				</Grid>
				<Grid item sx={{marginLeft: "6px"}}>
					<Typography sx={{textTransform: 'none'}} variant="subtitle2" color="text.primary">
						{props.quantity}
					</Typography>
				</Grid>
			</Grid>
		</Button>	
		<Dialog onClose={handleClose} open={open}
			
			sx={{pointerEvents: 'all',
				"& .MuiDialog-container": {
				"& .MuiPaper-root": {
					overflow:'hidden',
					maxWidth: "400px!important",
					maxHeight: "500px!important",
					height: "80vh",
					width: "90vw" , 
					}
				}
			}}>
			<DialogTitle sx={{ margin: 0, padding: 0}}>	
				<Box component="form" onSubmit={handleUsernameSearch}>
					<TextField
						spellCheck={false}
						variant="standard"
					  	autoComplete='off'
						onChange={e => handleUsernameSearchedChange(e)}
						focused
						sx={{ backgroundColor: "transparent", padding: "8px", borderRadius: "3px", width: "90%", margin: 0}}
						InputProps={{
							disableUnderline: true,
							endAdornment:(
								<InputAdornment position="end">
									<IconButton
										sx={{
											ml: 1,
											"&.MuiButtonBase-root:hover": {
												bgcolor: "transparent"
											}
										}}
										type="submit"
										aria-label="search user"
										edge="end">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
						placeholder=
							{props.type === 'following' ? 
								(props.userId === auth.currentUser.uid ? t("followListModalComponent.followingTitleOfCurrentUser") : t("followListModalComponent.followingTitle") + props.userData.username)
							: props.type === "followers" ? 
								(props.userId === auth.currentUser.uid ? t("followListModalComponent.followerTitleOfCurrentUser") : t("followListModalComponent.followerTitle") + props.userData.username)
								: null}
					/>
				</Box>
			</DialogTitle> 
			
			<Divider />
			{loading ? 
				<Box sx={{ display: 'flex',
					alignContent: 'center', 
					height: "100%",
					alignItems:"center",
					justifyContent:"center"}}>
					<CircularProgress />
				</Box> 
				: 
				(users.length !== 0 ?  
					<List dense sx={{ width: "100%" , bgcolor: 'transparent!important', height: '100%', overflow: 'auto' }}>
						{users.map((user, index) => {
							if (users.length === index + 1) {
								return (
									<>
										<ListItem
											key={user.userId}
											disablePadding
											sx={{padding: "6px", paddingLeft: "10px"}}
										>
											<FollowListItemComponent user={user} index={index} handleFollowFunction={e => handleFollowFunction(e, user.userId, user.following, index)} followingsLimit={followingsLimit}/>
										</ListItem>
										{
											isLastUser === false ?
												<Stack direction="row"
													justifyContent="center"
													alignItems="center">
													<Button  className='LoadMoreButton' fullWidth disabled={loading} onClick={getUsersFunction}>
														<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreUsers")}</Typography>
														<ArrowDropDownIcon />
													</Button>
												</Stack>
											:
											null
										}
									</>
								);
							} else {
								return (
									<ListItem
										key={user.userId}
										disablePadding
										sx={{padding: "6px", paddingLeft: "10px"}}
									>
										<FollowListItemComponent user={user} index={index} handleFollowFunction={e => handleFollowFunction(e, user.userId, user.following, index)} followingsLimit={followingsLimit}/>
									</ListItem>
								);
							}
						})}
						{loadingMoreUsers ? 
							<ListItem sx={{ display: 'flex',
							alignContent: 'center', 
							alignItems:"center",
							justifyContent:"center"}}>
								<CircularProgress />
							</ListItem>
							:
							null
						}
					</List>
					:
					<Box sx={{ display: 'flex',
					alignContent: 'center', 
					height: "100%",
					alignItems:"center",
					justifyContent:"center"}}>
						<Typography variant="caption">{t("followListModalComponent.userListIsEmpty")}</Typography>
					</Box>  
				)
			}
		</Dialog>
	  </div>
	);
}