import { functions } from "../../firebase";
import { convertBackendResponseKeysToFrontEndFormat } from "../../utils/ConvertObjectToFirstCharLowerCase";
import { httpsCallable } from "firebase/functions";

export async function createTopic(title, opening, open, invite){
	const createtopicincloudspanner = httpsCallable(functions, 'createtopicincloudspanner');
	return await createtopicincloudspanner({ title: title, opening: opening, open: open, invite: invite })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function deleteUserTopicInvitesNotifications(invite){
	const deleteuselesstopicinvitesnotificationscount = httpsCallable(functions, 'deleteuselesstopicinvitesnotificationscount');
	return await deleteuselesstopicinvitesnotificationscount({ invite: invite })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getTopicData(topicId) {

	const gettopicdatafromcloudspanner = httpsCallable(functions, 'gettopicdatafromcloudspanner');
	return await gettopicdatafromcloudspanner({ topicId: topicId })
	.then((result) => {
		const data = result.data;
		if (data !== null) {
			const res = {
				topic: {
					topicId: data.TopicId,
					title: data.Title,
					open: data.Open,
					reportedTitle: data.ReportedTitle,
					reportedOpening: data.ReportedOpening
				},
				post: {
					post: data.Opening,
					timestamp: data.UnixMillisTopicTimestamp,
					postId:data.PostId
				},
				user:{
					userId: data.UserId,
					avatarSmallUrl: data.AvatarSmallUrl,
					avatarMediumUrl: data.AvatarMediumUrl,
					avatarBigUrl: data.AvatarBigUrl,
					username: data.Username
				},
				opening: true,
			}
			return res
		} else {
			return null
		}
		
	})
	.catch((error) => {
	});
}
export async function getDiscussionData(topicId, discussionId) {
	if (discussionId === null){
		return null
	} else {
		const getdiscussiondatafromcloudspanner = httpsCallable(functions, 'getdiscussiondatafromcloudspanner');
		return await getdiscussiondatafromcloudspanner({ topicId: topicId, discussionId: discussionId })
		.then((result) => {
			const data = result.data;
			if (data === null){
				return null
			}else {
				const discussionInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data.discussion)
				const topicCreatorUserInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data.topicCreatorUser)
				const discussionCreatorUserInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data.discussionCreatorUser)
				return {discussion: discussionInLocalFormat, topicCreatorUser: topicCreatorUserInLocalFormat, discussionCreatorUser: discussionCreatorUserInLocalFormat}	
			}
		})
		.catch((error) => {
		});
	}
}

export async function getDiscussionPosts(topicId, discussionId, lastPostId, paramsPostId, firstPostId) {
	const getdiscussionspostsfromcloudspanner = httpsCallable(functions, 'getdiscussionspostsfromcloudspanner');
	return await getdiscussionspostsfromcloudspanner({ topicId: topicId, discussionId: discussionId, lastPostId: lastPostId, paramsPostId: paramsPostId, firstPostId: firstPostId })
	.then((result) => {
		const posts = result.data.posts;
		const data = posts !== null ? posts.map((row) => {
			return {
				post: {
					postId: row.PostId,
					first: row.First,
					post: row.Post,
					type: row.Type,
					sharedPostId: row.SharedPostId,
					sharedPostAuthorId: row.SharedPostAuthorId,
					timestamp: row.UnixMillisPostTimestamp,
					reportedPost: row.ReportedPost
				},
				user: {
					userId: row.UserId,
					username: row.Username,
					bio: row.Bio,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers, 
					points: row.Points,
					timestamp: row.UnixMillisUserTimestamp
				},
				postId: row.PostId,
			}
		}) : []
		return {data: data, isLastPost: result.data.isLastPost}
	})
	.catch((error) => {
	});
}
export async function getUserPosts(userId, lastPostId) {
	const getuserpostsfromcloudspanner = httpsCallable(functions, 'getuserpostsfromcloudspanner');
	return await getuserpostsfromcloudspanner({ userId: userId, lastPostId: lastPostId })
	.then((result) => {
		const posts = result.data.posts;
		const data = posts !== null ? posts.map((row) => {
			return {
				post: {
					postId: row.PostId,
					first: row.First,
					post: row.Post,
					type: row.Type,
					sharedPostId: row.SharedPostId,
					sharedPostAuthorId: row.SharedPostAuthorId,
					timestamp: row.UnixMillisPostTimestamp,
					reportedPost: row.ReportedPost  
				},
				sharedPost: {
					first: row.SharedPostFirst,
					post: row.SharedPostPost,
					timestamp: row.SharedPostUnixMillisPostTimestamp,
					reportedSharedPost: row.ReportedSharedPost
				},
				user: {
					userId: row.UserId,
					username: row.Username,
					bio: row.Bio,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers, 
					points: row.Points,
					timestamp: row.UnixMillisUserTimestamp
				},
				sharedPostUser: {
					userId: row.SharedPostUserUserId,
					username: row.SharedPostUserUsername,
					bio: row.SharedPostUserBio,
					avatarSmallUrl: row.SharedPostUserAvatarSmallUrl,
					avatarMediumUrl: row.SharedPostUserAvatarMediumUrl,
					avatarBigUrl: row.SharedPostUserAvatarBigUrl,
					follows: row.SharedPostUserFollows,
					followers: row.SharedPostUserFollowers, 
					points: row.SharedPostUserPoints,
					timestamp: row.SharedPostUserUnixMillisUserTimestamp
				},
				postId: row.PostId,
				topic: {
					topicId: row.TopicId,
					title: row.Title,
					timestamp: row.UnixMillisTopicTimestamp,
					open: row.Open,
					reportedTitle: row.ReportedTitle
				},
				sharedPostTopic: {
					topicId: row.SharedPostTopicsTopicId,
					title: row.SharedPostTopicsTitle,
					timestamp: row.SharedPostTopicsUnixMillisTopicTimestamp,
					reportedSharedTitle: row.ReportedSharedTitle
				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					followers: row.DiscussionFollowers,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					lastPostId: row.LastPostId

				},
				sharedPostDiscussion: {
					discussionId: row.SharedPostDiscussionsDiscussionId,
					timestamp: row.SharedPostDiscussionsUnixMillisDiscussionTimestamp,
					followers: row.SharedPostDiscussionsDiscussionFollowers,
					ratingsSum: row.SharedPostDiscussionsRatingsSum,
					ratingsCounter: row.SharedPostDiscussionsRatingsCounter,
					postsCounter: row.SharedPostDiscussionsPostsCounter
				},
			}
		}) : []
		return {data: data, isLastPost: result.data.isLastPost}	
	})
	.catch((error) => {
	});
}
export async function getLastDiscussionPost(topicId, discussionId) {
	const getlastdiscussionpostfromcloudspanner = httpsCallable(functions, 'getlastdiscussionpostfromcloudspanner');
	return await getlastdiscussionpostfromcloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const data = result.data;
		const row = data
		if (data === null){
			return null
		}else {
			const res = {
				post: {
					postId: row.PostId,
					first: row.First,
					post: row.Post,
					type: row.Type,
					sharedPostId: row.SharedPostId,
					sharedPostAuthorId: row.SharedPostAuthorId,
					timestamp: row.UnixMillisPostTimestamp,
					reportedPost: row.ReportedPost
				},
				user: {
					userId: row.UserId,
					username: row.Username,
					bio: row.Bio,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers, 
					points: row.Points,
					timestamp: row.UnixMillisUserTimestamp
				},
				postId: row.PostId,
			}
			return res
		}
	})
	.catch((error) => {
	});
	
}


export async function userAlreadyDiscussedThisTopic(topicId) {
	const useralreadydiscussedthistopicincloudspanner = httpsCallable(functions, 'useralreadydiscussedthistopicincloudspanner');
	return await useralreadydiscussedthistopicincloudspanner({ topicId: topicId })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function updateDiscussionCounters(topicId, discussionId) {
	const updatediscussioncountersincloudspanner = httpsCallable(functions, 'updatediscussioncountersincloudspanner');
	return await updatediscussioncountersincloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}


export async function replyTopicOpeningPost(topicId, reply){
	const replytotopicopeningpostincloudspanner = httpsCallable(functions, 'replytotopicopeningpostincloudspanner');
	return await replytotopicopeningpostincloudspanner({ topicId: topicId, reply: reply })
	.then((result) => {
		const topicCreatorId = result.data.topicCreatorId !== undefined && result.data.topicCreatorId !== null ? result.data.topicCreatorId : null;
		return {topicCreatorId: topicCreatorId, success: result.data.success, error: result.data.error, linksPerPostExceeded: result.data.linksPerPostExceeded, usernamesMentionsExceeded: result.data.usernamesMentionsExceeded, webRiskThreat: result.data.webRiskThreat, 
			userBlockedDueToWebRiskThreat: result.data.userBlockedDueToWebRiskThreat}
	})
	.catch((error) => {
	});
}
export async function deleteUserTopicRepliesNotifications(topicCreatorId){
	const deleteuselesstopicrepliesnotificationscount = httpsCallable(functions, 'deleteuselesstopicrepliesnotificationscount');
	return await deleteuselesstopicrepliesnotificationscount({ topicCreatorId: topicCreatorId })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function replyDiscussionPost(topicId, discussionId, reply){
	const replytodiscussionpostincloudspanner = httpsCallable(functions, 'replytodiscussionpostincloudspanner');
	return await replytodiscussionpostincloudspanner({ topicId: topicId, discussionId: discussionId, reply: reply })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function changeTopicOpen(topicId){
	
	const changetopicopenvalueincloudspanner = httpsCallable(functions, 'changetopicopenvalueincloudspanner');
	return await changetopicopenvalueincloudspanner({ topicId: topicId })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function updateDiscussionRating(topicId, discussionId, ratingValue){ 

	const updatediscussionratingincloudspanner = httpsCallable(functions, 'updatediscussionratingincloudspanner');
	return await updatediscussionratingincloudspanner({ topicId: topicId, discussionId: discussionId, ratingValue: ratingValue })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getDiscussionRating(topicId, discussionId){	

	const getcurrentuserdiscussionratingfromcloudspanner = httpsCallable(functions, 'getcurrentuserdiscussionratingfromcloudspanner');
	return await getcurrentuserdiscussionratingfromcloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const rating = result.data.rating
		return rating
	})
	.catch((error) => {
	});
}

export async function followDiscussion(topicId, discussionId){
	
	const followdiscussionincloudspanner = httpsCallable(functions, 'followdiscussionincloudspanner');
	return await followdiscussionincloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const data = result.data;
		if (data.error === true && data.discussionFollowsLimit === true) {
			return {success: false, discussionFollowsLimit: true}
		} else {
			if (data.success === true) {
				return {success: true, discussionFollowsLimit: false}
			} else {
				return {success: false, discussionFollowsLimit: false}
			}
		}
	})
	.catch((error) => {
	});
}
export async function unfollowDiscussion(topicId, discussionId){
	
	const unfollowdiscussionincloudspanner = httpsCallable(functions, 'unfollowdiscussionincloudspanner');
	return await unfollowdiscussionincloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const data = result.data.success;
		return data
	})
	.catch((error) => {
	});
}

export async function isDiscussionBeingFollowedByCurrentUser(topicId, discussionId){
	const isdiscussionbeingfollowedbycurrentuserincloudspanner = httpsCallable(functions, 'isdiscussionbeingfollowedbycurrentuserincloudspanner');
	return await isdiscussionbeingfollowedbycurrentuserincloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function updateDiscussionLastReadTimestamp(topicId, discussionId){
	const updatediscussionlastreadtimestampfromcloudspanner = httpsCallable(functions, 'updatediscussionlastreadtimestampfromcloudspanner');
	return await updatediscussionlastreadtimestampfromcloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
	})
	.catch((error) => {
	});
}

export async function getDiscussionsList(lastDiscussionId, unansweredDiscussions){
	const getdiscussionslistfromcloudspanner = httpsCallable(functions, 'getdiscussionslistfromcloudspanner');
	return await getdiscussionslistfromcloudspanner({ lastDiscussionId: lastDiscussionId, unansweredDiscussions: unansweredDiscussions })
	.then((result) => {
		const data = result.data.data;
		const resData = data !== null ? data.map((row) => {
			return {
				user: {
					userId: row.UserId,
					username: row.Username,
					bio: row.Bio,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers, 
					points: row.Points,
					timestamp: row.UnixMillisUserTimestamp
				},
				topic: {
					topicId: row.TopicId,
					title: row.Title,
					timestamp: row.UnixMillisTopicTimestamp,
					reportedTitle: row.ReportedTitle
				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					followers: row.DiscussionFollowers,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					authorOfLastPost: row.AuthorOfLastPost 
				}
			}
		}) : []
		return {data: resData, isLastDiscussion: result.data.isLastDiscussion}	
	})
	.catch((error) => {
	});
}

export async function getFollowingDiscussionsList(lastDiscussionId){
	const getfollowingdiscussionslistfromcloudspanner = httpsCallable(functions, 'getfollowingdiscussionslistfromcloudspanner');
	return await getfollowingdiscussionslistfromcloudspanner({ lastDiscussionId: lastDiscussionId })
	.then((result) => {
		const data = result.data.data;
		const resData = data !== null ? data.map((row) => {
			return {
				topic: {
					topicId: row.TopicId,
					title: row.Title,
					timestamp: row.UnixMillisTopicTimestamp,
					reportedTitle: row.ReportedTitle
				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					lastPostTimestamp: row.UnixMillisDiscussionLastPostTimestamp,
					lastReadTimestamp: row.UnixMillisDiscussionFollowersLastReadTimestamp,
					followers: row.DiscussionFollowers,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter
				},
				discussionRead: false
			}
		}) : []
		return {data: resData, isLastDiscussion: result.data.isLastDiscussion}	
	})
	.catch((error) => {
	});
}
export async function getCreatedTopicsList(lastTopicId, openTopics){
	const getcreatedtopicslistfromcloudspanner = httpsCallable(functions, 'getcreatedtopicslistfromcloudspanner');
	return await getcreatedtopicslistfromcloudspanner({ lastTopicId: lastTopicId, openTopics: openTopics })
	.then((result) => {
		const data = result.data.data;		
		const resData = data !== null ? data.map((row) => {
			return {
				topic: {
					topicId: row.TopicId,
					title: row.Title,
					timestamp: row.UnixMillisTopicTimestamp,
					open: row.Open,
					reportedTitle: row.ReportedTitle
				},
			}
		}) : []
		return {data: resData, isLastTopic: result.data.isLastTopic}	
	})
	.catch((error) => {
	});
}

export async function getDiscussionsFromTopicId(lastDiscussionId, topicId){
	const getdiscussionsfromtopicidfromcloudspanner = httpsCallable(functions, 'getdiscussionsfromtopicidfromcloudspanner');
	return await getdiscussionsfromtopicidfromcloudspanner({ lastDiscussionId: lastDiscussionId, topicId: topicId })
	.then((result) => {
		const data = result.data.data;
		const resData = data !== null ? data.map((row) => {
			return {
				userId: row.UserId,
				username: row.Username,
				avatarSmallUrl: row.AvatarSmallUrl,
				authorOfLastPost: row.AuthorOfLastPost,
				discussionId: row.DiscussionId
			}
		}) : []
		return {data: resData, isLastDiscussion: result.data.isLastDiscussion}
	})
	.catch((error) => {
	});
}

export async function getDiscussionParticipants(topicId, discussionId){
	const getparticipantsofdiscussionfromcloudspanner = httpsCallable(functions, 'getparticipantsofdiscussionfromcloudspanner');
	return await getparticipantsofdiscussionfromcloudspanner({ topicId: topicId, discussionId: discussionId })
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat = result.data !== null ? data.map((row) => { return row.UserId}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}

export async function getInvitedUsersIdsOfTopic(topicId){

	const getinvitedusersoftopicfromcloudspanner = httpsCallable(functions, 'getinvitedusersoftopicfromcloudspanner');
	return await getinvitedusersoftopicfromcloudspanner({ topicId: topicId })
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat = result.data !== null ? data.map((row) => { return row.UserId}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}

export async function getInvitedUsersOfTopic(topicId){

	const getinvitedusersoftopicfromcloudspanner = httpsCallable(functions, 'getinvitedusersoftopicfromcloudspanner');
	return await getinvitedusersoftopicfromcloudspanner({ topicId: topicId })
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat = result.data !== null ? data.map((row) => { return convertBackendResponseKeysToFrontEndFormat(row)}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}

export async function getTopicInvitations(lastTopicId, invitesFromFollows){
	const gettopicinvitationsfromcloudspanner = httpsCallable(functions, 'gettopicinvitationsfromcloudspanner');
	return await gettopicinvitationsfromcloudspanner({ lastTopicId: lastTopicId, invitesFromFollows:invitesFromFollows })
	.then((result) => {
		const data = result.data.data;
		const resData = data !== null ? data.map((row) => {
			return {
				topicId: row.TopicId,
				title: row.Title,
				reportedTitle: row.ReportedTitle
			}
		}) : []
		return {data: resData, isLastInvitation: result.data.isLastInvitation}
	})
	.catch((error) => {
	});
}

export async function deleteUserInvitation(userId, topicId){
	const deleteuserinvitationfromcloudspanner = httpsCallable(functions, 'deleteuserinvitationfromcloudspanner');
	return await deleteuserinvitationfromcloudspanner({ userId: userId, topicId: topicId })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function sharePost(topicId, discussionId, postId, author){
	const sharepostincloudspanner = httpsCallable(functions, 'sharepostincloudspanner');
	return await sharepostincloudspanner({ topicId: topicId, discussionId:discussionId, postId:postId, author: author })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});

}