import React, {useContext, useEffect, useState} from 'react'
import { auth } from '../firebase'
import { onAuthStateChanged, updatePassword, updateEmail, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, sendEmailVerification, deleteUser, onIdTokenChanged } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getCurrentUserProfile } from '../firebaseFunctions/spannerFunctions/profileFunctions';
import { writeUserLog } from '../firebaseFunctions/spannerFunctions/logFunctions';
import { updateUsersEmailListInCloudSpanner } from '../firebaseFunctions/spannerFunctions/policiesFunctions';
import { DEFAULT_LANGUAGE_CODE } from '../utils/Constants';
import { ColorModeContext } from '../App';

const AuthContext = React.createContext()

export function useAuth() {
  	return useContext(AuthContext)
}

export function AuthProvider({ children }) {
	const colorMode = React.useContext(ColorModeContext);
	const [currentUser, setCurrentUser] = useState()
	const [currentProfile, setCurrentProfile] = useState(false)
	const [isSigningUp, setIsSigningUp] = useState(false)
	const [isCreatingUser, setIsCreatingUser] = useState(false)
	const [loading, setLoading] = useState(true)


	function signUp(email, password){
		return createUserWithEmailAndPassword(auth, email,password).then(userCredential =>{
			sendEmailVerification(userCredential.user)
		})
	}
	function signIn(email, password) {
		return signInWithEmailAndPassword(auth, email, password).then((credential) => {
			writeUserLog()
			return credential
		})
	}
	function logOut() {
		colorMode.lightColorMode()
		return signOut(auth)
	}
	function resetPassword(email) {
		return sendPasswordResetEmail(auth, email)
	}
	function updateUserEmail(newEmail) {
		return updateEmail(auth.currentUser, newEmail)
	}
	function updateUserPassword(newPassword) {
		return updatePassword(auth.currentUser, newPassword)
	}
	function verifyUser() {
		return sendEmailVerification(auth.currentUser)
	}
	function deleteCurrentUser() {
		return deleteUser(auth.currentUser)
	}
	function setCurrentProfileForContext(value) {
		setCurrentProfile(value)
		setLoading(false)
	}
	function setCurrentUserForContext(value) {
		setCurrentUser(value)
		setLoading(false)
	}
	function setIsSigningUpForContext(value) {
		setIsSigningUp(value)
	}
	function setIsCreatingUserForContext(value) {
		setIsCreatingUser(value)
	}


	useEffect(() => {
		// to refresh token on email verification, so user can post inmediately
		const unsubscribeAuthToken = onIdTokenChanged(auth, (user) => {
			if (user) {
				//refresh token to update email verification
				user.getIdToken(true)		
				const lng = navigator.language.split('-')[0] || DEFAULT_LANGUAGE_CODE;
				updateUsersEmailListInCloudSpanner(lng)
			}
		})
		const unsubscribe = onAuthStateChanged(auth, (user) => {	
			setLoading(true)	
			if (user) {
				// user logged but userProfile is null correct error allowing user to create user profile
				getCurrentUserProfile().then((profile) => {
					if (profile) {		
						setCurrentUser(user)		
						setCurrentProfileForContext(true)
					} else {
						setCurrentUser(user)
						setCurrentProfileForContext(false)
					}
				})
			} else {
				setCurrentUser(user)
				setLoading(false)
			}
		})
		return () => {
			unsubscribeAuthToken()
			unsubscribe()
		}
	}, [])

	const value = {
		currentUser,
		currentProfile,
		isSigningUp,
		setCurrentUserForContext,
		setCurrentProfileForContext,
		setIsSigningUpForContext,
		setIsCreatingUserForContext, 
		signIn,
		signUp,
		logOut,
		resetPassword,
		updateUserEmail,
		updateUserPassword,
		verifyUser,
		deleteCurrentUser
	  }
	
	  return (
		<AuthContext.Provider value={value}>
		  	{!loading && !isCreatingUser ? children : 
				<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "45vh" }}>
					<CircularProgress />
				</Box>
			}
		</AuthContext.Provider>
	  )
	}