import React, {useState, useEffect} from 'react';
import './RecommendedUsersComponent.css'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { auth } from "../../firebase";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteUserFollowedNotifications } from '../../firebaseFunctions/spannerFunctions/followerFunctions';
import { AVATAR_SMALL_SIZE } from '../../utils/Constants';
import {AvatarPreviewComponent} from '..'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { followUser, isUserFollowed, unfollowUser } from '../../firebaseFunctions/spannerFunctions/followerFunctions';
import { getRecommendedUsers } from '../../firebaseFunctions/spannerFunctions/recommendedFunctions';

const SmallAvatar = styled(Avatar)({
	width: 20,
	height: 20,
	color: "white",
	border: '2px solid rgba(255, 255, 255, 0.86)!important'
  });
  
  const MediumAvatar = styled(Avatar)({
	width: 26,
	height: 26,
	zIndex: 1,
	color: "white",
	border: '2px solid rgba(255, 255, 255, 0.86)!important'
  });
  
  const CustomAvatarGroup = styled(AvatarGroup)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginLeft: '14px!important',
	'& .MuiAvatar-root': {
	  margin: '-5px',
	},
  });

  
function FollowListItemComponent(props) {
	const { t } = useTranslation();
	const index = props.index;
	const user = props.user;
	return (
		<>
			<ListItemAvatar>
				{user.avatarSmallUrl !== "" ?  														
					<AvatarPreviewComponent avatar={user.avatarSmallUrl}
						avatarBig={user.avatarBigUrl}
						size={AVATAR_SMALL_SIZE + "px"}
						/>
				:
					<Avatar sx={{ color: "white", width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE }} alt="avatar">
						{user.username[0].toUpperCase()}
					</Avatar>
				}
			</ListItemAvatar>
			<ListItemText id={user.userId} 
				sx={{flexShrink:1}}
				primary={
					<Typography style={{ width: 'inherit',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis' }} display='block' variant='body1'>
						<Link reloadDocument component={RouterLink} to={"/" + user.username} color="inherit" underline="none">
							<>
								{user.username}
							</>
						</Link>	
					</Typography>
				} 
			/>
			{user.userId !== auth.currentUser.uid && user.following !== null ? 
			<>
				<Button key={user.userId} variant={user.following ? "contained": "outlined"} 
					sx={{
						borderRadius: 5,
						padding: '4px',
						fontSize: '10.5px',
						marginRight:'12px'}} 
					disabled={user.loading || props.followingsLimit} onClick={(e) => props.handleFollowFunction(e, user.userId, user.following, index)}>{user.following ? t("unfollow") : t("follow")}</Button>
			</> : null}
		</>
	)
}

export default function RecommendedUsersComponent() {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true)
	const [loadingFollowFunction, setLoadingFollowFunction] = useState(false)
	const [lastUsername, setLastUsername] = useState(null)
	const [users, setUsers] = useState([])
	const [firstThreeUsers, setFirstThreeUsers] = useState([])
	const [loadingFirstThreeUsers ,setLoadingFirstThreeUsers] = useState(false)
	const [followingsLimit, setFollowingLimit] = useState(false)
	const [loadingMoreUsers ,setLoadingMoreUsers] = useState(false)
	
	const [isLastUser, setIsLastUser] = useState(false)
	const navigate = useNavigate()	

	useEffect(() => {
		setLoadingFirstThreeUsers(true)
		getRecommendedUsers(lastUsername).then((res) => {
			setFirstThreeUsers(res.data)
			setLoadingFirstThreeUsers(false)
		})
	}, [])
	async function getUsersFunction() {
		setLoading(true)
		getRecommendedUsers(lastUsername).then((res) => {
			setIsLastUser(res.isLastUser)
			res.data.forEach(user => {								
				isUserFollowed(user.userId).then((res2) => {
					const value = {...user, following: res2, loading:false, userId: user.userId}
					setUsers((prevUsers) => [ ...prevUsers, value ])
					setLastUsername(user.username)
				})
			})
			setLoading(false)
		})
	}


	const handleClickOpen = () => {
		setOpen(true);
		if (!loadingFollowFunction) {
			getUsersFunction()
		} else {
			setLoading(true)
		}
	};
	
	const handleClose = (event, reason) => {
		setUsers([])
		setLastUsername(null)
		setOpen(false);
	};

	async function handleFollowFunction(e, userId, following, index) {
		e.preventDefault()
		setLoadingFollowFunction(true)
		if (auth.currentUser.emailVerified) {
			var newArr = [...users]
			newArr[index].loading = true
			setUsers(newArr)
	
			if (following === true){
				const unfollowSuccess = await unfollowUser(userId)
				if (unfollowSuccess === true){
					var newArr2 = [...users]
					newArr2[index].following = false
					setUsers(newArr2)
				}
			} 
			if (following === false){
				const followingValue = await followUser(userId)
				
				if (followingValue.res === true){
					const followedUser = followingValue.followedUser
					var newArr3 = [...users]
					newArr3[index].following = true
					setUsers(newArr3)
					deleteUserFollowedNotifications(followedUser)
				}
	
				setFollowingLimit(followingValue.followingLimit)
			}
	
			var newArr4 = [...users]
			newArr4[index].loading = false
			setUsers(newArr4)
			setLoadingFollowFunction(false)
			setLoading(false)
		} else {
			navigate('/page/verifyuser')
		}
	}
	
	return (
		<>
			<Snackbar open={followingsLimit} autoHideDuration={6000}>
				<Alert severity='error' sx={{ width: '100%' }}>{t("followListModalComponent.followingLimit")}</Alert>
			</Snackbar>
			<Button
				variant="contained"
				className='SidebarButton'
				onClick={handleClickOpen}
				sx={{
					borderRadius: '12px!important',
					transition: '0s!important',
					marginTop:'8px!important',
					height:'40px!important',
					width: "inherit",
					backgroundColor: 'transparent!important',
					justifyContent: 'flex-start', 
					padding: '20px 0px!important',
					'&:hover': {
						backgroundColor: 'rgba(122, 122, 122, 0.06)!important',
					}, 
				}}
			>
				<CustomAvatarGroup max={3}>
					{firstThreeUsers.length >= 3 ? firstThreeUsers.map((user, index) => {
						if (index === 0) {
							if (user.avatarSmallUrl !== ""){  	
								return <SmallAvatar src={user.avatarSmallUrl} />							
							} else {
								return <SmallAvatar src={user.avatarSmallUrl}>
									<Typography color="inherit" variant="caption">
										{user.username[0].toUpperCase()}
									</Typography>
								</SmallAvatar>
							}
						}
						else if (index === 1) {
							if (user.avatarSmallUrl !== ""){  	
								return <MediumAvatar src={user.avatarSmallUrl} />							
							} else {
								return <MediumAvatar src={user.avatarSmallUrl}>
									<Typography color="inherit" variant="caption">
										{user.username[0].toUpperCase()}
									</Typography>
								</MediumAvatar>
							}
						}
						else if (index === 2) {
							if (user.avatarSmallUrl !== ""){  	
								return <SmallAvatar src={user.avatarSmallUrl} />							
							} else {
								return <SmallAvatar src={user.avatarSmallUrl}>
									<Typography color="inherit" variant="caption">
										{user.username[0].toUpperCase()}
									</Typography>
								</SmallAvatar>
							}
						}
					})
					:
						loadingFirstThreeUsers ? 
							<>						
								<CircularProgress sx={{margin: "6px", width:"26px!important", height:"26px!important", color:"primary!important"}}/>
							</>
							:
							<>
								<SmallAvatar />
								<MediumAvatar />
								<SmallAvatar />
							</>

					}
				</CustomAvatarGroup>	
				<Typography variant="body2" sx={{marginLeft: '12px!important'}} >{t("sidebar.recommendedUsers")}</Typography>
			</Button>
			<Dialog onClose={handleClose} open={open}
				sx={{pointerEvents: 'all',
					"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						overflow:'hidden',
						maxWidth: "400px!important",
						maxHeight: "500px!important",
						height: "80vh",
						width: "90vw" , 
						}
					}
				}}>
				<DialogTitle>	
					<Typography color="inherit" variant="h3">{t("recommendedUsersComponent.title")}</Typography>
				</DialogTitle> 
				
				<Divider />
				{loading ? 
					<Box sx={{ display: 'flex',
						alignContent: 'center', 
						height: "100%",
						alignItems:"center",
						justifyContent:"center"}}>
						<CircularProgress />
					</Box> 
					: 
					(users.length !== 0 ?  
						<List dense sx={{ width: "100%" , bgcolor: 'transparent!important', height: '100%', overflow: 'auto' }}>
							{users.map((user, index) => {
								if (users.length === index + 1) {
									return (
										<>
											<ListItem
												key={user.userId}
												disablePadding
												sx={{padding: "6px", paddingLeft: "10px"}}
											>
												<FollowListItemComponent user={user} index={index} handleFollowFunction={e => handleFollowFunction(e, user.userId, user.following, index)} followingsLimit={followingsLimit}/>
											</ListItem>
											{
												isLastUser === false ?
													<Stack direction="row"
														justifyContent="center"
														alignItems="center">
														<Button  className='LoadMoreButton' fullWidth disabled={loading} onClick={getUsersFunction}>
															<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreUsers")}</Typography>
															<ArrowDropDownIcon />
														</Button>
													</Stack>
												:
												null
											}
										</>
									);
								} else {
									return (
										<>
											<ListItem
												key={user.userId}
												disablePadding
												sx={{padding: "6px", paddingLeft: "10px"}}
											>
												<FollowListItemComponent user={user} index={index} handleFollowFunction={e => handleFollowFunction(e, user.userId, user.following, index)} followingsLimit={followingsLimit}/>
											</ListItem>
										</>
									);
								}
							})}
							{loadingMoreUsers ? 
								<ListItem sx={{ display: 'flex',
								alignContent: 'center', 
								alignItems:"center",
								justifyContent:"center"}}>
									<CircularProgress />
								</ListItem>
								:
								null
							}
						</List>
						:
						<Box sx={{ display: 'flex',
						alignContent: 'center', 
						height: "100%",
						alignItems:"center",
						justifyContent:"center"}}>
							<Typography variant="caption">{t("followListModalComponent.userListIsEmpty")}</Typography>
						</Box>  
					)
				}
			</Dialog>
		</>
	)

}