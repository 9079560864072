import React from 'react';
import './LegalNoticeComponent.css'
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import RecaptchaNoticeComponent from '../RecaptchaNoticeComponent/RecaptchaNoticeComponent';
function LegalNoticeComponent(props) {
	const { t } = useTranslation();
	
	return (
		<>
			<br/>
			<Typography variant="caption"  sx={{
				color: 'white',
				display: 'flex',
				justifyContent: 'center',
				textAlign:'center',
				alignItems: 'center'}}>
				<Link component={RouterLink} to={"/flow/terms"} color="inherit" underline="none">
					{t('legals.termsAndConditions')}
				</Link>	
			</Typography>
			<Typography variant="caption" sx={{
				color: 'white',
				display: 'flex',
				justifyContent: 'center',
				textAlign:'center',
				alignItems: 'center'}}>
				<Link component={RouterLink} to={"/flow/privacy"} color="inherit" underline="none">
					{t('legals.privacyPolicy')}
				</Link>	
			</Typography>
			<br/>
			<Typography variant="caption" sx={{
				color: 'white',
				display: 'flex',
				justifyContent: 'center',
				textAlign:'center',
				alignItems: 'center'}}>
				<RecaptchaNoticeComponent/>
			</Typography>
		</>
	);
  }


export default LegalNoticeComponent;