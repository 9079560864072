import React, {useState, useContext, useEffect} from 'react';
import './MyFeedComponent.css'
import { TopicSummaryItem, PostAdComponent} from '../../components'
import Grid from '@mui/material/Grid';

import { POST_AD_FREQUENCY} from '../../utils/Constants';
import { getCurrentUserFeedPosts, getCurrentUserFeedRecommendedPosts } from '../../firebaseFunctions/spannerFunctions/feedFunctions';
import { getCurrentUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';

import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import Typography from '@mui/material/Typography';

export default function MyFeedComponent(props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true)
	const [postList, setPostList] = useState([])
	const { setDocumentTitle } = useContext(DocumentTitleContext)

	const [user, setUser]  = useState();

	const [loadingMoreOlderPosts ,setLoadingMoreOlderPosts] = useState(false)
	const [isLastPost, setIsLastPost] = useState(false)
	const [loadingMoreNewerPosts ,setLoadingMoreNewerPosts] = useState(false)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)

	const [scrollHeight, setScrollHeight] = useState(0)
	const [scrollTop, setScrollTop] = useState(0)
	const [showRecommendedPostsOnFeed, setShowRecommendedPostsOnFeed]  = useState();

	useEffect(() => {  
		// Anything in here is fired on component mount.
		setDocumentTitle(t('documentTitles.feed'))
		setDocumentDescription(t('documentDescriptions.feed')) // Google search bot does not have access to feed, so generic description of the page should be on this route because it redirects to sign in
		
		const handleScroll = () => {
			setScrollHeight(document.documentElement.scrollHeight - document.documentElement.clientHeight)
			setScrollTop(window.scrollY || document.documentElement.scrollTop)	
		}

		getCurrentUserProfile().then((res) => {
			setUser(res)
		})
		
		const savedState = localStorage.getItem("showRecommendedPostsOnFeed"); 
		setShowRecommendedPostsOnFeed(savedState)
		if (savedState === "true") {
			// show recommended posts
			getCurrentUserFeedRecommendedPosts(null, null).then((res) => {
				setPostList(res.data)
				setIsLastPost(res.isLastPost)
				setLoading(false)
			})
		} else {
			if (savedState === null) {
				localStorage.setItem("showRecommendedPostsOnFeed", true);
				setShowRecommendedPostsOnFeed("true")
				// show recommended posts
				getCurrentUserFeedRecommendedPosts(null, null).then((res) => {
					setPostList(res.data)
					setIsLastPost(res.isLastPost)
					setLoading(false)
				})
			} else {
				// show user related posts
				getCurrentUserFeedPosts(null, null).then((res) => {
					setPostList(res.data)
					setIsLastPost(res.isLastPost)
					setLoading(false)
				})
			} 
		}
	
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}

    }, [])

	useEffect(() => {
		if (scrollTop === 0){
			if (postList.length > 0 && !loading && !loadingMoreNewerPosts){
				// fetch newer posts
				handleLoadNewPosts()
			}
		} else if (scrollTop === scrollHeight) {
			if (postList.length > 0 && !loading && !loadingMoreOlderPosts && !isLastPost){
				// fetch older posts
				handleLoadOldPosts()
			}
		}
	}, [scrollTop, scrollHeight])

	async function handleLoadNewPosts() {
		setLoadingMoreNewerPosts(true)
		if (showRecommendedPostsOnFeed === "true") {
			// show recommended posts
			getCurrentUserFeedRecommendedPosts(0, null).then((res) => {
				if (res !== undefined) {
					setPostList([...res.data])
					setIsLastPost(res.isLastPost)
				}
				setLoadingMoreNewerPosts(false)
			})
		} else {
			// show user related posts
			getCurrentUserFeedPosts(0, null).then((res) => {
				if (res !== undefined) {
					setPostList([...res.data])
					setIsLastPost(res.isLastPost)
				}
				setLoadingMoreNewerPosts(false)
			})
		}
	}
	async function handleLoadOldPosts() {
		setLoadingMoreOlderPosts(true)

		if (showRecommendedPostsOnFeed === "true") {
			// show recommended posts
			getCurrentUserFeedRecommendedPosts(postList.length, postList[postList.length - 1].postId).then((res) => {
				if (res !== undefined) {
					setPostList((prevList) => {
						const newList = [...prevList,...res.data]
						return newList
					})
					setIsLastPost(res.isLastPost)
				}
				setLoadingMoreOlderPosts(false)
			})
		} else {
			// show user related posts
			getCurrentUserFeedPosts(postList.length, postList[postList.length - 1].postId).then((res) => {
				if (res !== undefined) {
					setPostList((prevList) => {
						const newList = [...prevList,...res.data]
						return newList
					})
					setIsLastPost(res.isLastPost)
				}
				setLoadingMoreOlderPosts(false)
			})
		}
	}


	return (	<>
		{loading ?    
		
		<Box sx={{ display: 'flex',
			alignContent: 'center', 
			height: "100vh",
			alignItems:"center",
			justifyContent:"center"}}>
			<CircularProgress />
		</Box> 
		: <>
			{user &&
			
				<Grid item xs>
					{
						loadingMoreNewerPosts === true ?  
							<Box sx={{ display: 'flex',
								alignContent: 'center', 
								height: "auto",
								margin:"12px",
								alignItems:"center",
								justifyContent:"center"}}>
								<CircularProgress size='26px'/>
							</Box> 
						:
						null
					}
					{postList.length > 0 ? postList
						.map((post, index) => (
							(index + 1) % POST_AD_FREQUENCY === 0 ?
							<>
								<TopicSummaryItem type="my-feed" loading={loading} post={post.post} sharedPost={post.sharedPost} sharedPostUser={post.sharedPostUser} sharedPostTopic={post.sharedPostTopic}
								sharedPostDiscussion={post.sharedPostDiscussion} user={post.user} topic={post.topic} discussion={post.discussion} id={post.postId} key={post.postId}/>
								<PostAdComponent />	
							</>
							:
							<TopicSummaryItem type="my-feed" loading={loading} post={post.post} sharedPost={post.sharedPost} sharedPostUser={post.sharedPostUser} sharedPostTopic={post.sharedPostTopic}
							sharedPostDiscussion={post.sharedPostDiscussion} user={post.user} topic={post.topic} discussion={post.discussion} id={post.postId} key={post.postId}/>
					)) : 
							<Box sx={{ display: 'flex',
								alignContent: 'center', 
								alignItems:"center",
								justifyContent:"center", marginTop: "24px"}}>
									<Typography variant='caption'>{t('myFeed.noPosts')}</Typography>
							</Box> 
					}
					
					{
						loadingMoreOlderPosts === true ?  
							<Box sx={{ display: 'flex',
								alignContent: 'center', 
								height: "auto",
								margin:"12px",
								alignItems:"center",
								justifyContent:"center"}}>
								<CircularProgress size='26px'/>
							</Box> 
						:
						null
					}
					<div style={{padding: "6px"}}/>
				</Grid>
			}
		</>
			}
		</>
	)
}

