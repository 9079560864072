import React, {useState, useEffect} from 'react';
import './RecoverComponent.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import logoIconFinalColor from '../../utils/logoIconFinalColor.svg'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useAuth } from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next';
import { HOME_LOGO_ICON_WIDTH
	,SHOW_HOME_SLOGAN_WIDTH 
 } from '../../utils/Constants';
 import logoWhite from '../../utils/logoWhite.svg'

const theme = createTheme();

export default function SignInComponent() {
	const { t } = useTranslation();
	const { resetPassword } = useAuth()
	const [info, setInfo] = useState("")
	const [loading, setLoading] = useState(false)
	  
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)	
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
		setWindowHeight(window.innerHeight)
	}	
	useEffect(() => {  
	// Anything in here is fired on component mount.	
	window.addEventListener('resize', setWindowDimensions);
	return () => {
		window.removeEventListener('resize', setWindowDimensions)
	}

	}, [])
	async function handleSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);  
		
		try {
			setInfo("")
			setLoading(true)
			await resetPassword(data.get('email'))
			setInfo(t("recoverPassword.recoveryEmailSent"))
		} catch {
			setInfo(t("recoverPassword.recoveryEmailSent"))
		}
  
	  setLoading(false)
	}
	
	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
				>
				
					{info && <Alert severity='info'>{info}</Alert>}
					{
						windowWidth < SHOW_HOME_SLOGAN_WIDTH ?						
							<img src={logoWhite} style={{width:'80%', minWidth:'220px', marginTop:'10%'}} alt="logo" />
							:				
							<img src={logoIconFinalColor} width={HOME_LOGO_ICON_WIDTH} alt="logo" />
					}
					<Typography component="h1" variant="h6" sx={{color: "#f8f8f8!important"}}>
						{t("recoverPassword.recoverPassword")}
					</Typography>
					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="dense"
							required
							className='HomePageTextField'
							fullWidth
							spellCheck={false}
							id="email"
							label={t("emailAdress")}
							name="email"
							autoComplete="email"
							autoFocus
							size="small"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							className="FlowButton"
							sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
							disabled={loading}
							>
								{t("recoverPassword.sendRecoveryEmail")}
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	)
}
  

