import React, {useState, useContext, useEffect} from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import logoWhite from '../../utils/logoWhite.svg'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import './NavBar.css'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate, createSearchParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MAX_MOBILE_WIDTH, NAVBAR_ANONYMOUS_MAX_MOBILE_WIDTH, NAVBAR_HEIGHT, NAVBAR_LOGO_WIDTH, NAVBAR_MOBILE_LOGO_WIDTH} from '../../utils/Constants';
import NotificationsComponent from '../NotificationsComponent';
import DarkModeButtonComponent from '../DarkModeButtonComponent';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CreateIcon from '@mui/icons-material/Create';
import LoginIcon from '@mui/icons-material/Login';
import ListIcon from '@mui/icons-material/List';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { auth } from '../../firebase';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import Paper from '@mui/material/Paper';
import FeedPostsCustomizationSwitchComponent from '../FeedPostsCustomizationSwitchComponent';
const SignInTextField = styled((props) => (
	<TextField spellCheck={false} InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
	marginTop: "4px",
	marginRight: "8px",
	height: "34px",
	'& .MuiFilledInput-root': {
	  overflow: 'hidden',
	  borderRadius: "4px",
	  backgroundColor: '#fcfcfc',
	  border: '1px solid',
	  borderColor: '#bababa',
	  transition: theme.transitions.create([
		'border-color',
		'background-color',
		'box-shadow',
	  ]),
	  '&:hover': {
		backgroundColor: '#f5f5f5',
	  },
	  '&.Mui-focused': {
		backgroundColor: '#f5f5f5',
		boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`
	  },
	},
  }));
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: "22px",//theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, .2),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, .3),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 0, 1, 0),
    transition: theme.transitions.create('width')
  },
}));

export default function NavBar() {

	const { t } = useTranslation();
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const { signIn } = useAuth()
	const { logOut } = useAuth()
	const [loading, setLoading] = useState(false)
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const [logoutOpen, setLogoutOpen] = React.useState(false);	
	const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);	
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	
	const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
	}	
	useEffect(() => {  
		// Anything in here is fired on component mount.	

		window.addEventListener('resize', setWindowDimensions);
		return () => {
			window.removeEventListener('resize', setWindowDimensions)
		}

	}, [])
	async function handleSignInSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);  
		try {
			setLoading(true)
			await signIn(data.get('email'), data.get('password'))
			navigate("/")
		} catch {
			openErrorAlert()
		}

		setLoading(false)
	}
	
	async function handleSearch(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);  
		if (data.get("search") !== ""){
			
			setDocumentTitle(data.get('search').toString() + " - " + t('documentTitles.searchDefaultTitle'))
			
			navigate({
				pathname: "/page/results",
				search: createSearchParams({
					search_query: data.get("search")
				}).toString()
			});
		}
	}
	const navigate = useNavigate()
	const handleLogoutClickOpen = () => {
		setLogoutOpen(true);
	};

	const handleLogoutClose = () => {
		setLogoutOpen(false);
	};
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  	const handleNavigateToCreateTopic = () => {
		navigate("/page/newtopic")
	}
	const handleNavigateToManageTopics = () => {
		navigate("/page/manage")
	}
	const handleNavigateToUserMentions = () => {
		navigate("/page/mentions")
	}
	

  async function handleLogOut(event) {
	event.preventDefault()
	handleLogoutClose()
	try {
		setLoading(true)
		await logOut()
		navigate("/")
	} catch {
		alert(t("navBar.failedToLogOut"))
	}

    setLoading(false)
  }

  const handleProfileClick = (event) => {
	navigate("/page/profile")
  };
  
  
  const handleOpenMobileSearchBar = () => {
    setMobileSearchOpen(true);
  };
  const handleCloseMobileSearchBar = () => {
    setMobileSearchOpen(false);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openErrorAlert = () => {
	setErrorAlertOpen(true);
};

const closeErrorAlert = (event, reason) => {
	if (reason === 'clickaway') {
		return;
	}
	setErrorAlertOpen(false);
};
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
	<>
		<Drawer
			anchor={"left"}
			id={mobileMenuId}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
			onOpen={handleMobileMenuOpen}
		>
			<Paper sx={{height:'100%'}}>
				{auth.currentUser ? 
					<List sx={{marginTop: NAVBAR_HEIGHT}}> 
						<ListItem onClick={handleProfileClick}>
							<ListItemButton onClick={handleProfileClick} disabled={loading}>
								<ListItemIcon>
									<AccountCircle />
								</ListItemIcon>
								<ListItemText primary={<Typography variant="body1">{t("navBar.profile")}</Typography>} />
							</ListItemButton>
						</ListItem>						
						<Divider />
						<ListItem>
							<ListItemButton onClick={handleNavigateToCreateTopic}>
								<ListItemIcon>
									<CreateIcon />
								</ListItemIcon>
								<ListItemText primary={<Typography variant="body1">{t("navBar.createTopic")}</Typography>}/>
							</ListItemButton>
						</ListItem>

						<ListItem>
							<ListItemButton onClick={handleNavigateToManageTopics}>
								<ListItemIcon>
									<ListIcon/>
								</ListItemIcon>
								<ListItemText primary={<Typography variant="body1">{t("navBar.manageTopics")}</Typography>}/>
							</ListItemButton>
						</ListItem>
						
						<ListItem>
							<ListItemButton onClick={handleNavigateToUserMentions}>
								<ListItemIcon>
									<AlternateEmailIcon />
								</ListItemIcon>
								<ListItemText primary={<Typography variant="body1">{t("navBar.mentions")}</Typography>}/>
							</ListItemButton>
						</ListItem>
						<FeedPostsCustomizationSwitchComponent mobile={true}/>
						<Divider />
						<ListItem >					
							<DarkModeButtonComponent text={true}/>
						</ListItem>
						<ListItem >					
							<ListItemButton onClick={handleLogoutClickOpen} disabled={loading}>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText primary={<Typography variant="body1">{t("logOut")}</Typography>} />
							</ListItemButton>
						</ListItem>
					</List>
					:
					
					<Box component="form" onSubmit={handleSignInSubmit} noValidate sx={{ height: "40px", marginTop: NAVBAR_HEIGHT}}>
						<List >
							<ListItem>
								<SignInTextField
									required
									fullWidth
									id="email"
									label={t("emailAdress")}
									name="email"
									autoComplete="email"
									autoFocus
									size="small"
									variant="filled"
								/>
							</ListItem>
							<ListItem>
								<SignInTextField
									required
									fullWidth
									name="password"
									label={t("password")}
									type="password"
									id="password"
									autoComplete="current-password"
									size="small"
									variant="filled"
								/>
							</ListItem>
							<ListItem>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
									disabled={loading}
									>
									{t("signIn")}
								</Button>
							</ListItem>
							<ListItem>
								<Link component={RouterLink} to={"/flow/signup/"} underline="hover" sx= {{margin: 0, color: "#bababa"}}>
									<Typography  variant="caption" style={{ wordWrap: "break-word", color: "#bababa" }}>{t("dontHaveAccount")}</Typography>
								</Link>
							</ListItem>
						</List>
					</Box>
				}
			</Paper>
		</Drawer>
	</>
  )

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={closeErrorAlert}>
				<Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>{t("signInComponent.failedLogIn")}</Alert>
			</Snackbar>
			<Dialog
				open={logoutOpen}
				onClose={handleLogoutClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>
				<DialogTitle id="alert-dialog-title">
					{t("logOut")}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t("navBar.wantToLogOutQuestion")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="error" onClick={handleLogoutClose}>{t("cancel")}</Button>
					<Button onClick={handleLogOut} autoFocus>
						{t("yes")}
					</Button>
				</DialogActions>
			</Dialog>
			<AppBar position="fixed" sx={{height: NAVBAR_HEIGHT, zIndex: (theme) => theme.zIndex.drawer + 1 // zIndex to be able to see navbar while using textfield drawer in response to posts
			 }}>
				<Toolbar>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					>
					<Grid item>
							{
								windowWidth < MAX_MOBILE_WIDTH ?
									
									mobileSearchOpen === true ?
										<Stack direction="row" sx={{marginTop:  windowWidth < MAX_MOBILE_WIDTH ?  "0px" : 0}}>			
											<Search style={{height: "32px", marginTop:  windowWidth < MAX_MOBILE_WIDTH ? "0px" : "0px", width: windowWidth < MAX_MOBILE_WIDTH ? "45vw" : "30vw"}}>	
												<Box
													component="form"
													sx={{
														'& .MuiTextField-root': { m: 1, width: '25ch' },
														display: 'flex', alignItems: 'start'
													}}
													autoComplete="off"
													noValidate
													onSubmit={handleSearch}
												>
													<StyledInputBase
														spellCheck={false}
														name="search"
														id="search"
														required
														style={{height: "34px", paddingLeft: windowWidth < MAX_MOBILE_WIDTH ? "12px" : "50px", width: windowWidth < MAX_MOBILE_WIDTH ? "calc(45vw - 12px)" : "calc(30vw - 22px)"}}
														placeholder={t("navBar.search")}
														inputProps={{ 'aria-label': 'search' }}
														/>
														<IconButton
															size="small"
															aria-label="close-search"
															onClick={handleCloseMobileSearchBar}
															color="inherit"
															disabled={loading}
															>
																<CloseIcon fontSize='small'/>
														</IconButton>
												</Box>
											</Search>
										</Stack>
										:	
										<Stack direction="row" sx={{marginTop:  windowWidth < MAX_MOBILE_WIDTH ?  "0px" : 0}}>				
											<Link component={RouterLink} to={"/"} color="inherit" underline="none" sx={{
													marginTop:  windowWidth < MAX_MOBILE_WIDTH ? "5px" : "-5px",
													width: windowWidth < MAX_MOBILE_WIDTH ? NAVBAR_MOBILE_LOGO_WIDTH : NAVBAR_LOGO_WIDTH, marginLeft: windowWidth < MAX_MOBILE_WIDTH ? "5px" : "20px", paddingRight: windowWidth < MAX_MOBILE_WIDTH ? "7px" : "16px"
													,':hover': {	
														backgroundColor: "transparent!important"
													}
												}} 
												className='NavbarLogo'>
												{
													windowWidth < MAX_MOBILE_WIDTH ?
														<img src={logoWhite} alt="intergraphy_logo" style={{ width: "inherit!important" }} />
													:
														<img src={logoWhite} alt="intergraphy_logo"/>
												}
											</Link>
										</Stack>
								:	
								<>
									<Stack direction="row" sx={{marginTop:  windowWidth < MAX_MOBILE_WIDTH ?  "0px" : 0}}>			
										<Link component={RouterLink} to={"/"} color="inherit" underline="none" sx={{
												marginTop:  windowWidth < MAX_MOBILE_WIDTH ? "5px" : "-5px",
												width: windowWidth < MAX_MOBILE_WIDTH ? NAVBAR_MOBILE_LOGO_WIDTH : NAVBAR_LOGO_WIDTH, marginLeft: windowWidth < MAX_MOBILE_WIDTH ? "5px" : "20px", paddingRight: windowWidth < MAX_MOBILE_WIDTH ? "7px" : "16px"
												,':hover': {	
													backgroundColor: "transparent!important"
												}
											}} 
											className='NavbarLogo'>
											{
												windowWidth < MAX_MOBILE_WIDTH ?
													<img src={logoWhite} alt="intergraphy_logo" style={{ width: "inherit!important" }} />
												:
													<img src={logoWhite} alt="intergraphy_logo"/>
											}
										</Link>
										<Search style={{height: "32px", marginTop:  windowWidth < MAX_MOBILE_WIDTH ? "8px" : "0px", width: windowWidth < MAX_MOBILE_WIDTH ? "45vw" : "30vw"}}>
											<SearchIconWrapper>
												<SearchIcon />
											</SearchIconWrapper>		
											<Box
												component="form"
												sx={{
													'& .MuiTextField-root': { m: 1, width: '25ch' }
												}}
												autoComplete="off"
												noValidate
												onSubmit={handleSearch}
											>
												
												<StyledInputBase
													spellCheck={false}
													name="search"
													id="search"
													required
													style={{height: "34px", paddingLeft: windowWidth < MAX_MOBILE_WIDTH ? "12px" : "50px", width: windowWidth < MAX_MOBILE_WIDTH ? "calc(45vw - 12px)" : "calc(30vw - 22px)"}}
													placeholder={t("navBar.search")}
													inputProps={{ 'aria-label': 'search' }}
													/>
											</Box>
										</Search>
									</Stack>
								</>			
							}
					</Grid>
					{auth.currentUser ? 

						<Grid item>
							<Box sx={{ display: { xs: 'none', md: 'flex' }, marginTop: "-4px" }}>
								
								<DarkModeButtonComponent text={false}/>
								<NotificationsComponent />
								<IconButton
								size="large"
								aria-label="profile configuration"
								onClick={handleProfileClick}
								color="inherit"
								>
									<AccountCircle />
								</IconButton>
								<IconButton
								size="large"
								aria-label="log out"
								onClick={handleLogoutClickOpen}
								color="inherit"
								disabled={loading}
								>
									<LogoutIcon />
								</IconButton>
							</Box>
							<Box sx={{ display: { xs: 'flex', md: 'none' }, marginTop: "0px" }}>
								
								{windowWidth < MAX_MOBILE_WIDTH ?
									
									mobileSearchOpen === false ?
									<IconButton
										size="large"
										aria-label="open-search"
										onClick={handleOpenMobileSearchBar}
										color="inherit"
										disabled={loading}
										>
										<SearchIcon />
									</IconButton> : null 
									: null}
								<IconButton
									size="large"
									aria-label="show more"
									aria-controls={mobileMenuId}
									aria-haspopup="true"
									onClick={handleMobileMenuOpen}
									color="inherit"
									>
									<MoreIcon />
								</IconButton>
							</Box>
						</Grid>
					: 
						windowWidth > NAVBAR_ANONYMOUS_MAX_MOBILE_WIDTH ?
							<Grid item>
								<Stack direction="column" sx={{ display: { xs: 'none', md: 'flex' }}}>
									<Box component="form" onSubmit={handleSignInSubmit} noValidate sx={{ display: { xs: 'none', md: 'flex' }, height: "40px"}}>
										<SignInTextField
											required
											fullWidth
											id="email"
											label={t("emailAdress")}
											name="email"
											autoComplete="email"
											autoFocus
											size="small"
											variant="filled"
										/>
										<SignInTextField
											required
											fullWidth
											name="password"
											label={t("password")}
											type="password"
											id="password"
											autoComplete="current-password"
											size="small"
											variant="filled"
										/>
										
										<IconButton
											type="submit"
											fullWidth
											variant="contained"
											sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
											disabled={loading}
											>
											<LoginIcon sx={{color: "#fcfcfc", marginTop: "6px"}}/>
										</IconButton>
									</Box>
									<Link component={RouterLink} to={"/flow/signup/"} color="inherit" underline="hover" sx= {{margin: 0, marginTop: "-5px"}}>
										<Typography  variant="caption" style={{ wordWrap: "break-word", color: "white" }}>{t("dontHaveAccount")}</Typography>
									</Link>
								</Stack>
							</Grid>
							:
							<Grid item>		
							{windowWidth < MAX_MOBILE_WIDTH ?
									
								mobileSearchOpen === false ?
								<IconButton
									size="large"
									aria-label="open-search"
									onClick={handleOpenMobileSearchBar}
									color="inherit"
									disabled={loading}
									>
									<SearchIcon />
								</IconButton> : null 
								: null}				
								<IconButton
									size="large"
									aria-label="show more"
									aria-haspopup="true"
									onClick={handleMobileMenuOpen}
									color="inherit"
									>
									<LoginIcon sx={{color: "#fcfcfc"}}/>
								</IconButton>
							</Grid>
					}
				</Grid>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
		</Box>
	);
}
